import React from "react";
import ReactDOM from "react-dom/client";
import Snowfall from 'react-snowfall'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Auth from "./Authentication/auth";
import "./index.scss";
import Loader from "./shade/Loaders/Loaders";
import DashboardSelector from "./components/Dashboard/Dashboard-2/DashboardSelector";

const App = React.lazy(() => import("../src/shade/layouts/App"));
const Switcherapp = React.lazy(() =>
  import("../src/shade/layouts/Switcherapp")
);
const Custompages = React.lazy(() =>
  import("../src/shade/layouts/custompages")
);

//App

const Users = React.lazy(() => import("./components/Pages/Users/Users"));

const SignIn = React.lazy(() =>
  import("./components/Pages/Authentication/SignIn/SignIn")
);
const ForgotPassword = React.lazy(() =>
  import("./components/Pages/Authentication/ForgotPassword/ForgotPassword")
);
const Lockscreen = React.lazy(() =>
  import("./components/Pages/Authentication/Lockscreen/Lockscreen")
);
const ResetPassword = React.lazy(() =>
  import("./components/Pages/Authentication/ResetPassword/ResetPassword")
);
const UnderConstruction = React.lazy(() =>
  import(
    "./components/Pages/Authentication/UnderConstruction/UnderConstruction"
  )
);
const Error404 = React.lazy(() =>
  import("./components/Pages/Authentication/404Error/404Error")
);
const Error500 = React.lazy(() =>
  import("./components/Pages/Authentication/500Error/500Error")
);
const Error501 = React.lazy(() =>
  import("./components/Pages/Authentication/501Error/501Error")
);

const EmptyPage = React.lazy(() =>
  import("./components/Pages/EmptyPage/EmptyPage")
);

const AuthLogin = React.lazy(() => import("./Authentication/Login"));
//Form
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <Snowfall/>
    <BrowserRouter>
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}/`} element={<Auth />}>
            <Route index element={<AuthLogin />} />

            <Route
              path={`${process.env.PUBLIC_URL}/authentication/login`}
              element={<AuthLogin />}
            />
          </Route>

          <Route path={`${process.env.PUBLIC_URL}/`} element={<App />}>
            <Route
              path={`${process.env.PUBLIC_URL}/dashboard`}
              element={<DashboardSelector />}
            />

            <Route>
              <Route
                path={`${process.env.PUBLIC_URL}/clients`}
                element={<Users />}
              />
            </Route>

            <Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/Authentication/501error`}
                element={<Error501 />}
              />
            </Route>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/`} element={<Custompages />}>
            <Route
              path={`${process.env.PUBLIC_URL}/pages/Authentication/sigin`}
              element={<SignIn />}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/pages/Authentication/forgotpassword`}
              element={<ForgotPassword />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/pages/Authentication/resetpassword`}
              element={<ResetPassword />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/locked`}
              element={<Lockscreen />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/pages/Authentication/underconstruction`}
              element={<UnderConstruction />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/pages/Authentication/404error`}
              element={<Error404 />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/pages/Authentication/500error`}
              element={<Error500 />}
            />
            <Route path="*" element={<Error404 />} />
          </Route>

          <Route>
            <Route
              path={`${process.env.PUBLIC_URL}/pages/switcher/switcher-1`}
              element={<Switcherapp />}
            />
          </Route>
          <Route>
            <Route
              path={`${process.env.PUBLIC_URL}/pages/empty`}
              element={<EmptyPage />}
            />
          </Route>
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
