//axios requests function
import axios from "axios";
import {API_URL} from "../api/Constants";
import CookiesStore from "./CookiesStore";

export default async function fetcher(method, url, data, headers) {
  const jwtToken = CookiesStore.getJwtToken();
    return axios({
      method: method,
      url: API_URL + url,
      data: data,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        ...headers,
      },
    });

};