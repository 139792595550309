import { Card, Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

export default function WelcomeSalesManger({ name, company, role, refLink }) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {}, []);

  const copyToClipboard = async (link) => {
    try {
      await navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  };

  return (
    <Card className={"p-2"}>
      <Card.Body>
        <Row
          style={{
            /*  backgroundColor: "red",*/
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Col xl={5} lg={5} md={5} sm={7}>
            <div className="text-justified align-items-center">
              <h3 className="text-dark font-weight-semibold mb-2 mt-0">
                Hi, Welcome Back <span className="text-primary">{name}! </span>{" "}
              </h3>{" "}
              <Row>
                <p className="text-dark tx-14 mb-3 lh-3">
                  Your role is {role} for{" "}
                  <span className="text-primary">
                    {company?.map((item, index) => {
                      let name = item.replace(/_/g, " ");
                      return index === company.length - 1 ? name : name + ", ";
                    })}
                  </span>
                </p>
                <p className="text-dark tx-14 mb-3 lh-3">
                  Your referral link is <br></br>{" "}
                  <span className="referral-link">{refLink} </span>
                  <button
                    onClick={() => copyToClipboard(refLink)}
                    className="btn ripple btn-primary mt-2"
                  >
                    {copied ? "Copied!" : "Copy"}
                  </button>
                </p>
              </Row>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
