import React, { useEffect } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { getUser } from "../../../api/users/getUser.tsx";
import Welcome from "./Welcome";
import PendingBuyDeals from "../PendingDeals/PendingBuyDeals.tsx";
import DealData from "../Deal/DealData.tsx";
import WorkerHistory from "../History/WorkerHistory.tsx";
import CryptoCards from "../SafeCards/CryptoCards";
import PendingRewards from "../PendingDeals/PendingRewards.tsx";
import DealModal from "../Modals/DealModal";
import PendingSellDeals from "../PendingDeals/PendingSellDeals";
import SafeCards from "../SafeCards/SafeCards";
import DealDataCard from "../Deal/card/DealDataCard";

const SenderDashboard = () => {
  const [user, setUser] = React.useState();
  const [costModalShow, setCostModalShow] = React.useState(false);
  const [deal, setDeal] = React.useState();
  const [dealType, setDealType] = React.useState();
  const [reward, setReward] = React.useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [filter, setFilter] = React.useState("");

  useEffect(() => {
    if (!user) {
      getUser().then((res) => {
        let userData = res.data;
        //   console.log(userData.companies);
        setUser(userData);
      });
    } else {
    }
  }, [user, user?.companies]);

  useEffect(() => {}, [user?.companies]);
  useEffect(() => {
    //   console.log(reward);
  }, [reward]);

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            SENDER DASHBOARD
          </span>
        </div>
        <div className="justify-content-center mt-2"></div>
      </div>
      {/* <!-- /breadcrumb --> */}

      {/* <!-- row --> */}
      <Row>
        <Col xxl={9} xl={12} lg={12} md={12} sm={12}>
          <Col xl={12} lg={12} md={12} sm={12} className="px-0">
            {" "}
            <Welcome
              name={user?.firstName + " " + user?.lastName}
              company={user?.companies}
              role={"CRYPTO SENDER"}
              setFilter={setFilter}
            />
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              className="px-0"
              hidden={!deal}
            >
              <Card>
                <Card.Header
                  className={
                    "d-flex justify-content-between align-items-center"
                  }
                >
                  <Card.Title>Deal</Card.Title>
                  <i
                    className={"fe fe-close"}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setDeal(null)}
                  />
                </Card.Header>
                <Card.Body>
                  {deal && user?.companies[0] !== "VISA_MASTERCARD" ? (
                    <DealData
                      dealSearchData={deal}
                      type={dealType}
                      isSender={true}
                    />
                  ) : deal && user?.companies[0] === "VISA_MASTERCARD" ? (
                    <DealDataCard
                      dealSearchData={deal}
                      type={dealType}
                      isSender={true}
                    />
                  ) : null}
                </Card.Body>
              </Card>

              {/*     <Card>
                <Card.Header>
                  <Card.Title>Reward</Card.Title>
                </Card.Header>
                <Card.Body>{reward ? <div>asdgf</div> : null}</Card.Body>
              </Card>*/}
            </Col>
          </Col>
          {user?.companies[0] !== "VISA_MASTERCARD" ? (
            <Col xl={12} lg={12} md={12} sm={12} className="px-0">
              <Row>
                <Col sm={12} lg={12} xl={12}>
                  <Card>
                    <Card.Header className=" border-bottom-0 d-flex">
                      <div>
                        <h3 className="card-title mb-2">PENDING CRYPTO</h3>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <PendingBuyDeals
                        company={user?.companies}
                        openDeal={setDeal}
                        dealType={setDealType}
                        statuses={"paid"}
                        isSender={true}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          ) : null}

          <Col xl={12} lg={12} md={12} sm={12} className="px-0">
            <Row>
              {user?.companies[0] === "VISA_MASTERCARD" ? (
                <Col sm={12} lg={12} xl={12}>
                  <Card className="custom-card overflow-hidden">
                    <Card.Header className=" border-bottom-0 d-flex">
                      <h3 className="card-title mb-2 ">PENDING CASH</h3>
                      <div className="card-options ms-auto"></div>
                    </Card.Header>
                    <Card.Body>
                      <PendingSellDeals
                        company={user?.companies}
                        openDeal={setDeal}
                        dealType={setDealType}
                        filter={filter}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              ) : null}{" "}
              <Col sm={12} lg={12} xl={12}>
                <Card className="custom-card" style={{ overflow: "scroll" }}>
                  <Card.Header className=" border-bottom-0 d-flex">
                    <h3 className="card-title mb-2 ">PENDING REWARDS</h3>
                    <div className="card-options ms-auto"></div>
                  </Card.Header>
                  <Card.Body>
                    {/* <PendingSellDeals
                      company={user?.companies}
                      openDeal={setDeal}
                      dealType={setDealType}
                    />*/}

                    <Modal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      centered="true"
                      id=""
                    >
                      <Modal.Header>
                        <h6 className="modal-title">Update Deal</h6>
                        <Button
                          variant=""
                          type="button"
                          onClick={() => setModalShow(false)}
                        >
                          <span aria-hidden="true" className="text-dark">
                            <i className="fe fe-close"></i>
                          </span>
                        </Button>
                      </Modal.Header>
                      <Modal.Body>
                        <Col>
                          <DealModal
                            closeModal={setModalShow}
                            deal={reward?.requestNumber}
                            status={"paid"}
                            type={"reward"}
                            isSender={true}
                          />
                        </Col>
                      </Modal.Body>
                    </Modal>
                    <PendingRewards
                      setReward={setReward}
                      setModalShow={setModalShow}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} lg={12} xl={4}></Col>
              <Col lg={12} xl={4} md={12}></Col>
              <Col lg={12} xl={4} md={12}></Col>
            </Row>
          </Col>
        </Col>
        <Col xs={12} lg={12} md={12} xl={12} xxl={3}>
          {/*  <Card className="overflow-hidden">
            <Card.Header
              className="pb-1"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Modal
                show={costModalShow}
                onHide={() => setCostModalShow(false)}
                centered="true"
                id=""
              >
                <Modal.Header>
                  <h6 className="modal-title">Add Cost</h6>
                  <Button
                    variant=""
                    type="button"
                    onClick={() => setCostModalShow(false)}
                  >
                    <span aria-hidden="true" className="text-dark">
                      <i className="fa fa-times-circle"></i>
                    </span>
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <Col>
                    <CostsModal
                      closeModal={setCostModalShow}
                      company={user?.companies}
                    />
                  </Col>
                </Modal.Body>
              </Modal>
              <h3 className="card-title mb-2">{} SAFE</h3>
              <Button
                variant=""
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  setCostModalShow(true);
                }}
              >
                Record cost
              </Button>
            </Card.Header>
            <Card.Body className=" p-0 customers mt-1">
              {" "}
              <SafeCards company={user?.companies[0]} />
            </Card.Body>
          </Card>*/}
          {user?.companies[0] === "VISA_MASTERCARD" ? (
            <Card className="overflow-hidden">
              <Card.Header
                className="pb-1"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <h3 className="card-title mb-2">SAFE</h3>
              </Card.Header>
              <Card.Body className=" p-0 customers mt-1">
                <SafeCards company={user?.companies[0]} />
              </Card.Body>
            </Card>
          ) : (
            <CryptoCards
              costModalShow={costModalShow}
              setCostModalShow={setCostModalShow}
              user={user}
            />
          )}

          <Card className="overflow-hidden">
            <Card.Header className=" pb-1">
              <h3 className="card-title mb-2">Recent Transactions</h3>
            </Card.Header>
            <Card.Body className=" p-0 customers mt-1">
              <WorkerHistory />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!-- row  --> */}

      {/* <!-- /row --> */}
    </div>
  );
};
SenderDashboard.propTypes = {};

SenderDashboard.defaultProps = {};

export default SenderDashboard;
