import { Spinner } from "react-bootstrap";
import React from "react";

export function SmallLoader() {
  return (
    <Spinner
      animation="border"
      className="spinner-border spinner-border-sm"
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </Spinner>
  );
}

export function LargeLoader() {
  return (
    <div className="text-center mg-b-20">
      <Spinner animation="border" className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
}
