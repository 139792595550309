import fetcher from "../../utils/Fetcher";

export class Safe {
  currencies: { [key: string]: Currency };
  totalNet: number;
}

export class Currency {
  total: number;
  pending: number;
  net: null;
}

export async function getSafe(company: string) {
  // @ts-ignore
  return await fetcher("get", "/companies/" + company + "/safes", {}, {});
}
