import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { getUser } from "../../../../api/users/getUser.tsx";
import WelcomeSalesManager from "../sales/WelcomeSalesManager";
import SalesManagerKPI from "./SalesManagerKPI";
import { BasicTable } from "../../Tables/BasicTable";
import {
  Client,
  ClientsFilters,
  getClients,
} from "../../../../api/users/getClients";
import { Deal, getSalesManagerDeals } from "../../../../api/deals/getDeals";

const SalesManagerDashboard = () => {
  const [user, setUser] = React.useState();
  const [clients, setClients] = useState([]);
  const [deals, setDeals] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = React.useState([]);
  const [filter, setFilter] = React.useState("");
  const [sort, setSort] = React.useState("desc");

  const [totalElementsDeal, setTotalElementsDeals] = useState(0);
  const [totalPagesDeals, setTotalPagesDeals] = useState(0);
  const [currentPageDeals, setCurrentPageDeals] = useState(0);
  const [pageSizeDeals, setPageSizeDeals] = useState(10);
  const [searchDeals, setSearchDeals] = useState("");
  const [filtersDeals, setFiltersDeals] = React.useState([]);
  const [filterDeals, setFilterDeals] = React.useState("");
  const [sortDeals, setSortDeals] = React.useState("desc");

  useEffect(() => {
    if (!user) {
      getUser().then((res) => {
        let userData = res.data;
        setUser(userData);
      });
    }
    // let filters = new ClientsFilters();
    // filters.firstLevel = ["true", "false"];
    // filters.secondLevel = ["true", "false"];
    // setFilters(filters);
  }, []);

  useEffect(() => {
    let filters = new ClientsFilters();
    filters.firstLevel = ["true", "false"];
    filters.secondLevel = ["true", "false"];
    setFilters(filters);
  }, []);

  useEffect(() => {
    let filters = new ClientsFilters();
    filters.firstLevel = ["true", "false"];
    filters.secondLevel = ["true", "false"];
    setFiltersDeals(filters);
  }, []);

  useEffect(() => {
    getClients(pageSize, currentPage, search, filter, sort).then((response) => {
      let clients = [];
      response.data.content.forEach((el) => {
        let client = new Client();
        client.email = el.email;
        client.firstName = el.firstName;
        client.lastName = el.lastName;
        client.signInLastTimeAt = new Date(
          el.signInLastTimeAt
        ).toLocaleString();
        client.verifiedAt = new Date(el.verifiedAt).toLocaleString();
        client.locked = el.locked;
        client.personnelBuyFee = el.personnelBuyFee;
        client.personnelSellFee = el.personnelSellFee;
        clients.push(client);
      });
      if (clients.length === 0) {
        setClients(clients);
        setTotalElements(response.data.totalElements);
        setTotalPages(response.data.totalPages);
      } else if (clients.length > 0) {
        setTotalElements(response.data.totalElements);
        setTotalPages(response.data.totalPages);
        return setClients(clients);
      }
    });
  }, [currentPage, filter, pageSize, search, sort]);

  useEffect(() => {
    getSalesManagerDeals(
      pageSizeDeals,
      currentPageDeals,
      searchDeals,
      filterDeals,
      sortDeals
    ).then((response) => {
      let deals = [];
      response.data.content.forEach((el) => {
        let deal = new Deal();
        deal.dealNumber = el.dealNumber;
        deal.type = el.type;
        deal.createdAt = new Date(el.createdAt).toLocaleString();
        deal.closedAt = new Date(el.closedAt).toLocaleString();
        deal.cryptoValue = el.cryptoValue;
        deal.cryptoCode = el.cryptoCode;
        deal.fiatValue = el.fiatValue;
        deal.fiatCode = el.fiatCode;
        deal.paymentMethodFee = el.paymentMethodFee;
        deals.push(deal);
      });
      if (deals.length === 0) {
        setDeals(deals);
        setTotalElementsDeals(response.data.totalElements);
        setTotalPagesDeals(response.data.totalPages);
      } else if (deals.length > 0) {
        setTotalElementsDeals(response.data.totalElements);
        setTotalPagesDeals(response.data.totalPages);
        return setDeals(deals);
      }
    });
  }, [currentPageDeals, filterDeals, pageSizeDeals, searchDeals, sortDeals]);

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">DASHBOARD</span>
        </div>
        <div className="justify-content-center mt-2"></div>
      </div>
      {/* <!-- /breadcrumb --> */}

      {/* <!-- row --> */}
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
          <Col xl={12} lg={12} md={12} sm={12} className="px-0">
            {" "}
            <WelcomeSalesManager
              name={user?.firstName + " " + user?.lastName}
              company={user?.companies}
              role={user?.roles
                .map((role) => role.replace("ROLE_", "").replace("_", " "))
                .join(", ")}
              refLink={user?.referralLink}
            />
            <Col xl={12} lg={12} md={12} sm={12} className="px-0"></Col>
          </Col>

          <SalesManagerKPI />

          <Col xl={12} lg={12} md={12} sm={12} className="px-0">
            <Row>
              <Col sm={12} lg={12} xl={12}>
                <Card className="custom-card overflow-hidden">
                  <Card.Header className=" border-bottom-0 d-flex">
                    <h3 className="card-title mb-2 ">CLIENTS</h3>
                    <div className="card-options ms-auto"></div>
                  </Card.Header>
                  <Card.Body>
                    <div>
                      <BasicTable
                        data={clients}
                        headers={[
                          {
                            Header: "Email",
                            accessor: "email",
                            className: "borderrigth",
                          },
                          {
                            Header: "Name",
                            accessor: "firstName",
                            className: "borderrigth",
                          },
                          {
                            Header: "Surname",
                            accessor: "lastName",
                            className: "borderrigth",
                          },
                          {
                            Header: "sell fee",
                            accessor: "personnelSellFee",
                            className: "borderrigth",
                          },
                          {
                            Header: "buy fee",
                            accessor: "personnelBuyFee",
                            className: "borderrigth",
                          },
                          {
                            Header: "sign in last time",
                            accessor: "signInLastTimeAt",
                            className: "borderrigth",
                          },
                          {
                            Header: "verified at",
                            accessor: "verifiedAt",
                            className: "borderrigth",
                          },
                          {
                            Header: "is locked",
                            accessor: "locked",
                            className: "borderrigth",
                          },
                        ]}
                        size={pageSize}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        setPage={setCurrentPage}
                        setSize={setPageSize}
                        setSearch={setSearch}
                        filters={filters}
                        currentFilter={filter}
                        setFilter={setFilter}
                        sort={sort}
                        setSort={setSort}
                        searchOptions={{ placeholder: "email" }}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm={12} lg={12} xl={12}>
                <Card className="custom-card overflow-hidden">
                  <Card.Header className=" border-bottom-0 d-flex">
                    <h3 className="card-title mb-2 ">DEALS</h3>
                    <div className="card-options ms-auto"></div>
                  </Card.Header>
                  <Card.Body>
                    <div>
                      <BasicTable
                        data={deals}
                        headers={[
                          {
                            Header: "Deal number",
                            accessor: "dealNumber",
                            className: "borderrigth",
                          },
                          {
                            Header: "Type",
                            accessor: "type",
                            className: "borderrigth",
                          },
                          {
                            Header: "Crypto Value",
                            accessor: "cryptoValue",
                            className: "borderrigth",
                          },
                          {
                            Header: "Crypto code",
                            accessor: "cryptoCode",
                            className: "borderrigth",
                          },
                          {
                            Header: "Fiat value",
                            accessor: "fiatValue",
                            className: "borderrigth",
                          },
                          {
                            Header: "Fiat code",
                            accessor: "fiatCode",
                            className: "borderrigth",
                          },
                          {
                            Header: "Created at",
                            accessor: "createdAt",
                            className: "borderrigth",
                          },
                          {
                            Header: "closed at",
                            accessor: "closedAt",
                            className: "borderrigth",
                          },
                          {
                            Header: "Profit",
                            accessor: "paymentMethodFee",
                            className: "borderrigth",
                          },
                        ]}
                        size={pageSizeDeals}
                        currentPage={currentPageDeals}
                        totalPages={totalPagesDeals}
                        setPage={setCurrentPageDeals}
                        setSize={setPageSizeDeals}
                        setSearch={setSearchDeals}
                        filters={filtersDeals}
                        currentFilter={filterDeals}
                        setFilter={setFilterDeals}
                        sort={sortDeals}
                        setSort={setSortDeals}
                        searchOptions={{
                          placeholder: "WILL BE AVAILABLE SOON",
                          isDisabled: true,
                        }}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

SalesManagerDashboard.propTypes = {};

SalesManagerDashboard.defaultProps = {};

export default SalesManagerDashboard;
