import { Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import { getSafe } from "../../../api/companies/getSafe.tsx";
import { SmallLoader } from "../Loaders";

function SafeCards({ company, totalNet, ignoreCash }) {
  //use forEach for safes array
  const [safe, setSafe] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (company !== undefined) {
      setIsLoading(true);
      const interval = setInterval(() => {
        getSafe(company)
          .then((res) => {
            //   console.log(company);
            setSafe(res.data);
            setIsLoading(false);
          })
          .catch((error) => {
            //  console.log(error);
          });
      }, 5000);
    } else {
    }
  }, [company]);

  return (
    <div>
      <div
      /* className={"d-flex p-0 pd-t-2 pd-b-2"}
                      style={{
                        width: "100%",
                        height: "100%",
                        alignItems: "start",
                        marginBottom: "20px",
                       flexDirection: "column",
                      }}*/
      >
        {isLoading ? <SmallLoader /> : null}
        {ignoreCash ? (
          <SafeCard
            currency={"USDT"}
            total={safe?.currencies["USDT"].total}
            pending={safe?.currencies["USDT"].pending}
            net={safe?.currencies["USDT"].net}
            isSender={ignoreCash}
            companyName={company}
          />
        ) : safe ? (
          Object.keys(safe?.currencies).map((key) => {
            return (
              <SafeCard
                key={key}
                currency={safe?.currencies[key].currency}
                total={safe?.currencies[key].total}
                pending={safe?.currencies[key].pending}
                net={safe?.currencies[key].net}
              />
              /*  <h3 className="m-b-0 font-light">
                                                                                                                                                                                              </h3>*/
            );
          })
        ) : null}
      </div>
      <Col className={"pd-y-10"}> Total net : {safe?.totalNet}</Col>
    </div>
  );
}

function SafeCard({ currency, total, pending, net, isSender, companyName }) {
  const [percentage, setPercentage] = React.useState(0);
  useEffect(() => {
    setPercentage(Math.round((net / total) * 100));
  }, [net, total]);

  let color = "primary";
  // set red color for 0-15 and green for 85-100
  if (percentage < 15) {
    color = "secondary";
  } else if (percentage < 35) {
    color = "warning";
  } else {
    color = "success";
  }

  return (
    <div className={"wd-100p px-4 bd bd-y-1 pd-t-20"}>
      <div>
        <div
          className="d-flex align-items-center mb-2"
          style={{ justifyContent: "space-between" }}
        >
          <h6 className="tx-semibold tx-18 ">{currency.toUpperCase()}</h6>
          {isSender ? (
            <span
              className="badge bg-info mg-md-0 wd-100"
              style={{
                marginBottom: "0.5rem",
              }}
            >
              {companyName}
            </span>
          ) : null}
        </div>
      </div>
      {/*  <div className="col-3 d-flex align-items-center justify-content-center">

        </div>*/}

      <div className="">
        <Row>
          <Col className={"d-flex flex-column justify-content-between"}>
            <Row className={"d-flex flex-row justify-content-between  "}>
              <h6 className="mb-2 text-muted">TOTAL</h6>

              <h4 className="tx-16 font-weight-semibold mb-0">{total}</h4>
            </Row>
            <Row className={"d-flex flex-row justify-content-between  "}>
              <h6 className="mb-2 text-muted">PENDING</h6>

              <h4 className="tx-16 font-weight-semibold mb-0">{pending}</h4>
            </Row>
            <Row className={"d-flex flex-row justify-content-between  "}>
              <h6 className="mb-4 text-muted">NET</h6>
              <h4 className="tx-16 font-weight-semibold mb-0">{net}</h4>
            </Row>
          </Col>
          {/* <Col

                className="d-flex align-items-center justify-content-center upgrade-chart-circle"
            >
              <div className="chart-circle float-md-end mt-0 mt-md-0 mb-4 pd-0 wd-100p">
                <ReactApexChart
                    options={Radialbar.options}
                    series={Radialbar.series}
                    type="radialBar"
                    height={180}
                />
                <div className="chart-circle-value circle-style">
                  <div className="tx-18 font-weight-semibold">
                    {percentage}%
                  </div>
                </div>
              </div>
            </Col>
*/}
        </Row>
      </div>
      <div className="mb-3">
        <div className="progress progress-sm h-1 mb-1">
          <div
            style={{ width: percentage + "%" }}
            className={"progress-bar bg-" + color + " wd-" + percentage + "p"}
            role="progressbar"
          ></div>
        </div>
        <small className="mb-0 text-muted">
          Free<span className="float-end text-muted">{percentage}%</span>
        </small>
      </div>
    </div>
  );
}

export default SafeCards;
