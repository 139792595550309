import { Link } from "react-router-dom";
import { Button, ListGroup, ListGroupItem } from "react-bootstrap";
import { useEffect, useState } from "react";
//@ts-ignore
import { getHistory, NetDocument } from "../../../api/netDocs/getNetDocs.tsx";
import { SmallLoader } from "../Loaders";

// @ts-ignore
import arrowRight from "../../../assets/img/svg/chevrons-right.svg"; // @ts-ignore
import arrowLeft from "../../../assets/img/svg/chevrons-left.svg";
import { getInvoice } from "../../../api/deals/getDeals";
import axios from "axios";
import CookiesStore from "../../../utils/CookiesStore";

function WorkerHistory({ filter }) {
  const [history, setHistory] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    getHistory() // @ts-ignore
      .then((res) => {
        //  console.log(res); // @ts-ignore
        setHistory(res.data.content as NetDocument[]);
        setLoading(false);
      }) // @ts-ignore
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  }, [loading]);

  useEffect(() => {
    //in history array leave only those elements that include filter string
    //  console.log(filter);
    if (!filter) {
      //   console.log("filter empty");
      setFilteredList([]);
      setLoading(true);
    } else {
      let lowerFilter = filter.toLowerCase();
      //  console.log(filteredList.length);
      setFilteredList(
        history.filter((el) => {
          return (
            el?.number?.toString()?.toLowerCase()?.includes(lowerFilter) ||
            el?.dealNumber?.toLowerCase()?.includes(lowerFilter) ||
            el?.dealCode?.toLowerCase()?.includes(lowerFilter) ||
            el?.details?.toLowerCase()?.includes(lowerFilter)
          );
        })
      );
    }
  }, [filter]);

  return (
    <ListGroup
      className="list-lg-group list-group-flush"
      style={{ maxHeight: "40rem", overflowY: "scroll", cursor: "default" }}
    >
      {loading ? <SmallLoader /> : null}
      {error ? (
        <div className="alert alert-danger mg-t-10 mg-x-10" role="alert">
          {error}
        </div>
      ) : null}
      <div className="border-0" style={{ cursor: "default" }}>
        {filteredList
          ? filteredList.map((d: NetDocument, index: number) => {
              if (filter !== null) {
                /*if (!d.dealNumber.includes(filter)) {
                                                                return;
                                                              }*/
              }
              let color = d?.type === "buy" ? "primary" : "secondary";
              return (
                <ListGroupItem
                  className="list-group-item-action border-0"
                  key={index}
                >
                  <div className="media mt-0">
                    {d.type === "buy" ? (
                      <span className="me-3 bg-primary-transparent text-primary transaction-icon">
                        <img
                          className={"my-arrows"}
                          src={arrowRight}
                          alt="arrow-right"
                        />
                      </span>
                    ) : (
                      <span className="me-3 bg-secondary-transparent text-secondary transaction-icon">
                        <img
                          className={"my-arrows"}
                          src={arrowLeft}
                          alt="arrow-left"
                        />
                      </span>
                    )}

                    <div className="media-body">
                      <div className="d-flex align-items-center">
                        <div className="mt-0">
                          <h5 className="mb-1 tx-13 font-weight-sembold text-dark">
                            <span className="me-3 bg-success-gradient">
                              {d?.type === "buy" || d?.type === "sell"
                                ? d?.dealNumber
                                : d?.number}
                            </span>
                          </h5>
                          <div
                            style={{
                              fontSize: "12px",
                            }}
                          ></div>
                          <p className="mb-0 tx-12 text-muted">
                            {new Date(d?.generatedAt).toLocaleString()}
                          </p>
                        </div>
                        <span className="ms-auto wd-50p tx-12">
                          <span
                            className={
                              "text-" + color + " tx-11 text-end d-block"
                            }
                            style={{ textTransform: "uppercase" }}
                          >
                            {d?.type}
                          </span>
                          <span
                            className={
                              "float-end text-" +
                              color +
                              " font-weight-semibold"
                            }
                            style={{ textTransform: "uppercase" }}
                          >
                              {`${d?.type === "buy" ? "+" : "-"}${d?.amount} ${d?.currency}`}{d?.remainderBalance ? `, -${d.remainderBalance.amount} ${d.remainderBalance.code}` : ""}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderBottom: "1px #434f634a solid",
                      marginBottom: "0rem",
                      paddingBottom: "1.5rem",
                      maxHeight: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    <small
                      style={{
                        overflow: "clip",
                      }}
                    >
                      {d?.details}
                    </small>
                  </div>
                </ListGroupItem>
              );
            })
          : null}
        {filteredList.length === 0
          ? history.map((d: NetDocument, index: number) => {
              if (filter !== null) {
                /*if (!d.dealNumber.includes(filter)) {
                                                          return;
                                                        }*/
              }
              let color = d?.type === "buy" ? "primary" : "secondary";
              return (
                <ListGroupItem
                  className="list-group-item-action border-0"
                  key={index}
                >
                  <div className="media mt-0">
                    {d.type === "buy" ? (
                      <span className="me-3 bg-primary-transparent text-primary transaction-icon">
                        <img
                          className={"my-arrows"}
                          src={arrowRight}
                          alt="arrow-right"
                        />
                      </span>
                    ) : (
                      <span className="me-3 bg-secondary-transparent text-secondary transaction-icon">
                        <img
                          className={"my-arrows"}
                          src={arrowLeft}
                          alt="arrow-left"
                        />
                      </span>
                    )}

                    <div className="media-body">
                      <div className="d-flex align-items-center">
                        <div className="mt-0">
                          <h5 className="mb-1 tx-13 font-weight-sembold text-dark">
                            <span className="me-3">
                              {d?.type === "buy" || d?.type === "sell"
                                ? d?.dealNumber
                                : d?.number}
                            </span>
                          </h5>
                          <div
                            style={{
                              fontSize: "12px",
                            }}
                          ></div>
                          <p className="mb-0 tx-12 text-muted">
                            {new Date(d?.generatedAt).toLocaleString()}
                          </p>
                        </div>
                        <span className="ms-auto wd-50p tx-12">
                          <span
                            className={
                              "text-" + color + " tx-11 text-end d-block"
                            }
                            style={{ textTransform: "uppercase" }}
                          >
                            {d?.type}
                          </span>
                          <span
                            className={
                              "float-end text-" +
                              color +
                              " font-weight-semibold"
                            }
                            style={{ textTransform: "uppercase" }}
                          >
                              {`${d?.type === "buy" ? "+" : "-"}${d?.amount} ${d?.currency}`}{d?.remainderBalance ? `, -${d.remainderBalance.amount} ${d.remainderBalance.code}` : ""}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderBottom: "1px #434f634a solid",
                      marginBottom: "0rem",
                      paddingBottom: "1.5rem",
                      maxHeight: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    <small
                      style={{
                        overflow: "clip",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        downloadPdf(
                          `https://worker.safemans-teams.work/api/v1/invoices/${d?.dealNumber}`,
                          `${d?.dealNumber}.pdf`,
                          CookiesStore.getJwtToken()
                        );
                      }}
                    >
                      {d?.type === "buy" || d?.type === "sell"
                        ? "Download Invoice"
                        : d?.details}
                    </small>
                  </div>
                </ListGroupItem>
              );
            })
          : null}
      </div>
    </ListGroup>
  );
}
const downloadPdf = (url, filename, jwtToken) => {
  axios({
    url,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  })
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    })
    .catch((error) => {
      console.error("Error downloading PDF:", error);
    });
};
export default WorkerHistory;
