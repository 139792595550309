import fetcher from "../../utils/Fetcher";

export class Kpi {
  totalProfit: string;
  managerBonus?: string;
  totalClients?: string;
  firstLevelClients?: string;
  secondLevelClients?: string;
  activeClients?: string;
}

export async function getSalesManagerKPI() {
  return await fetcher("get", "/sales/kpi", {}, {});
}
