//show cookie files
import { Cookies } from "react-cookie";

export default class CookiesStore {
  //export jwtToken from cookies
  static setJwtToken(jwtToken) {
    const cookies = new Cookies();
    cookies.set("jwtToken", jwtToken);
  }

  static getJwtToken() {
    const cookies = new Cookies();
    return cookies.get("jwtToken");
  }

  //delete jwtToken from cookies
  static deleteJwtToken() {
    const cookies = new Cookies();
    cookies.remove("jwtToken");
  }
  static getAllCookies() {
    const cookies = new Cookies();
    return cookies.getAll();
  }
  static deleteAllCookies() {
    const cookies = new Cookies();
    let arr = cookies.getAll();
    for (let key in arr) {
      cookies.remove(key);
    }
  }
}
