import { useEffect, useState } from "react";
// @ts-ignore
import { getPendingRewards, Reward } from "../../../api/rewards/getRewards.tsx";
import { Button, Modal, Table } from "react-bootstrap";
import { SmallLoader } from "../Loaders";

// @ts-ignore
function PendingRewards({ company, setReward, dealType, setModalShow }) {
  const [rewards, setRewards] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // @ts-ignore
    getPendingRewards()
      // @ts-ignore

      .then((res) => {
        //  console.log(res);
        // @ts-ignore
        setRewards(res.data as Reward[]);
        setLoading(false);
      })
      // @ts-ignore

      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading ? <SmallLoader /> : null}
      {error ? (
        <div className="alert alert-danger mg-t-10 mg-x-10" role="alert">
          {error}
        </div>
      ) : null}
      <Table className={"list-lg-group list-group-flush"}>
        <thead>
          <tr style={{ fontSize: "12px" }}>
            <th style={{ fontSize: "12px" }}>Reward number</th>
            <th style={{ fontSize: "12px" }}>Created at</th>
            <th style={{ fontSize: "12px" }}>Name</th>
            <th style={{ fontSize: "12px" }}>Wallet</th>
            <th style={{ fontSize: "12px" }}>USDT value</th>
            <th style={{ fontSize: "12px" }}>Status</th>
            <th style={{ fontSize: "12px" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rewards.map((d: Reward, index: number) => {
            return (
              <tr key={index} className={"tx-12"}>
                <th>{d.requestNumber}</th>
                <th>{new Date(d.createdAt).toLocaleString()}</th>
                <th>{d.fullName}</th>
                <th>{d.wallet}</th>
                <th>{d.totalAmount}</th>
                <th>
                  <span className={"badge bg-secondary me-1 my-2"}>
                    {d.status}
                  </span>{" "}
                </th>

                <th>
                  <Button
                    className="btn-primary btn-md btn-block"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      //  console.log(d);
                      setReward(d);
                      setModalShow(true);
                      /* dealType("sell");*/
                    }}
                  >
                    Pay
                  </Button>
                </th>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Modal.Footer>
        <span>Total rewards: {rewards.length}</span>
        <span>Pending rewards: {rewards.length}</span>
      </Modal.Footer>
    </div>
  );
}

export default PendingRewards;
