import fetcher from "../../utils/Fetcher";
import {Statuses} from "../../types/types";

export class Deal {
    userId!: string;
    type!: string;
    createdAt!: string;
    paidAt!: string;
    expiredAt!: string;
    rate!: string;
    network!: string;
    cryptoCode!: string;
    fiatCode!: string;
    paymentMethodCode!: string;
    paymentMethodFee!: string;
    status!: Statuses;
    cryptoValue!: string;
    originalCryptoValue!: string;
    fiatValue!: string;
    serviceFee!: string;
    dealNumber!: string;
    dealCode!: string;
    wallet!: string;
    firstName!: string;
    lastName!: string;
    cardNumber?: string;
    cardExpiredDate?: string;
    cardHolderFirstName?: string;
    cardHolderLastName?: string;
    remainderBalance?: RemainderBalance;
}

export class RemainderBalance {
    amount!: string;
    code!: string;
}

export async function getSalesManagerDeals(size, page, search, filter, sort) {
    let url = search
        ? "/sales/deals?value=" + search
        : "/sales/deals?" +
        "size=" +
        size +
        "&page=" +
        page +
        filter;

    return await fetcher("get", url, {}, {});
}


export async function getPendingSellDeals(company: string) {
    return await fetcher(
        "get",
        "/deals?exchangeType=sell&statuses=paid,cash_prepared"
    );
}

export async function getPendingBuyDeals(statuses: string) {
    return await fetcher("get", "/deals?exchangeType=buy&statuses=" + statuses);
}

export async function getDeal(deal: string, type: string) {
    return await fetcher("get", `/deals/${deal}?exchangeType=${type}`);
}

export async function updateDealWallet(deal: string, wallet: string) {
    return await fetcher(
        "put",
        `/deals/${deal}?exchangeType=buy`,
        {wallet},
        {}
    );
}

export async function updatePaidAmount(
    deal: string,
    paidAmount: string,
    currencyCode: string
) {
    return await fetcher(
        "put",
        `/deals/${deal}/recalculate?exchangeType=buy`,
        {paidAmount, currencyCode},
        {}
    );
}

export async function updateBuyDealStatus(deal: string, status: string) {
    return await fetcher("put", `/deals/${deal}/payout?exchangeType=buy`, {}, {});
}

export async function updateSellDealStatus(deal: string, status: string) {
    return await fetcher(
        "put",
        `/deals/${deal}/close/byWorker?exchangeType=sell`,
        {},
        {}
    );
}

export async function updateVisaSellDealStatus(deal: string, status: string) {
    return await fetcher(
        "put",
        `/deals/${deal}/close/bySender?exchangeType=sell`,
        {},
        {}
    );
}

export async function closeBuyDeal(deal: string, status: string) {
    return await fetcher(
        "put",
        `/deals/${deal}/close/bySender?exchangeType=buy`,
        {},
        {}
    );
}

export async function updateDealStatus(deal: string, status: string) {
    return await fetcher("post", `/internal/deals/` + deal + `/update`, {}, {});
}

export async function declineDeal(deal: string, status: string) {
    return await fetcher("put", `/deals/` + deal + `/expired`, {}, {});
}

export async function getInvoice(dealNumber: string) {
    return await fetcher("get", `/invoices/${dealNumber}`, {}, {});
}

export async function getPhoneNumber(userId: string) {
    return await fetcher("get", `/users/${userId}/phone`, {}, {});
}
