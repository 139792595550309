import fetcher from "../../utils/Fetcher";

export default async function exchangeCurrency(
  amountFrom,
  currencyFrom,
  amountTo,
  currencyTo
) {
  return await fetcher("post", `/exchange-currency`, {
    incomeAmount: amountFrom,
    incomeCurrency: currencyFrom,
    outcomeAmount: amountTo,
    outcomeCurrency: currencyTo,
  });
}
