import fetcher from "../../../utils/Fetcher";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Col, Row } from "react-bootstrap";

function Plan() {
  async function getKpiDaily() {
    return await fetcher("get", "/kpi/deals-revenue?period=today", {}, {});
  }
  const [dailyValue, setDailyValue] = useState(0);
  async function getKpiWeekly() {
    return await fetcher("get", "/kpi/deals-revenue?period=week", {}, {});
  }
  const [weeklyValue, setWeeklyValue] = useState(0);
  async function getKpiMonthly() {
    return await fetcher("get", "/kpi/deals-revenue?period=month", {}, {});
  }
  const [monthlyValue, setMonthlyValue] = useState(0);

  useEffect(() => {
    getKpiDaily().then((res) => {
      //take from res.data.content only objects with type === "buy" and "sell"

      setDailyValue(res.data.totalDealsRevenueKPI);
    });
    getKpiWeekly().then((res) => {
      //take from res.data.content only objects with type === "buy" and "sell"

      setWeeklyValue(res.data.totalDealsRevenueKPI);
    });
    getKpiMonthly().then((res) => {
      //take from res.data.content only objects with type === "buy" and "sell"

      setMonthlyValue(res.data.totalDealsRevenueKPI);
    });
  }, []);

  const RadialbarDaily = {
    className: "forth circle",
    series: [dailyValue],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100],
      },
    },
    options: {
      colors: [
        dailyValue < 30 ? "#FF4560" : dailyValue < 80 ? "#FFB64D" : "#38c8b3",
      ],

      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          hollow: {},
        },
      },
    },
  };
  const RadialbarWeekly = {
    className: "forth circle",
    series: [weeklyValue],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100],
      },
    },
    options: {
      colors: [
        weeklyValue < 30 ? "#FF4560" : weeklyValue < 80 ? "#FFB64D" : "#38c8b3",
      ],

      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          hollow: {},
        },
      },
    },
  };
  const RadialbarMonthly = {
    className: "forth circle",
    series: [monthlyValue],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100],
      },
    },
    options: {
      colors: [
        monthlyValue < 30
          ? "#FF4560"
          : monthlyValue < 80
          ? "#FFB64D"
          : "#38c8b3",
      ],

      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          hollow: {},
        },
      },
    },
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">yOur success</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className={"mg-t-40 "}>
          <Col
            className="upgrade-chart-circle"
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "24px",
            }}
          >
            <div
              className="chart-circle"
              style={{
                zoom: "1.2",
              }}
            >
              <ReactApexChart
                options={RadialbarWeekly.options}
                series={RadialbarWeekly.series}
                type="radialBar"
                height={180}
              />
              <div
                className="chart-circle-value circle-style"
                style={{
                  borderColor:
                    weeklyValue < 30
                      ? "#FF4560"
                      : weeklyValue < 80
                      ? "#FFB64D"
                      : "#38c8b3",
                }}
              >
                <div className="tx-18 font-weight-semibold">{weeklyValue}%</div>
              </div>
            </div>{" "}
            <h3 className="tx-16 tx-normal mg-b-0 mg-t-10">Week</h3>
          </Col>{" "}
          <Col
            className="upgrade-chart-circle"
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flexWrap: "nowrap",
              gap: "24px",
            }}
          >
            <div
              className="chart-circle"
              style={{
                zoom: "1.5",
              }}
            >
              <ReactApexChart
                options={RadialbarDaily.options}
                series={RadialbarDaily.series}
                type="radialBar"
                height={180}
              />
              <div
                className="chart-circle-value circle-style"
                style={{
                  borderColor:
                    dailyValue < 30
                      ? "#FF4560"
                      : dailyValue < 80
                      ? "#FFB64D"
                      : "#38c8b3",
                }}
              >
                <div className="tx-18 font-weight-semibold">{dailyValue}%</div>
              </div>
            </div>
            <h3 className="tx-16 tx-normal mg-b-0 mg-t-10">Today</h3>
          </Col>
          <Col
            className="upgrade-chart-circle"
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "24px",
            }}
          >
            <div
              className="chart-circle"
              style={{
                zoom: "0.8",
              }}
            >
              <ReactApexChart
                options={RadialbarMonthly.options}
                series={RadialbarMonthly.series}
                type="radialBar"
                height={180}
              />
              <div
                className="chart-circle-value circle-style"
                style={{
                  borderColor:
                    monthlyValue < 30
                      ? "#FF4560"
                      : monthlyValue < 80
                      ? "#FFB64D"
                      : "#38c8b3",
                }}
              >
                <div className="tx-18 font-weight-semibold">
                  {monthlyValue}%
                </div>
              </div>
            </div>{" "}
            <h3 className="tx-16 tx-normal mg-b-0 mg-t-10">Month</h3>
          </Col>
        </Row>{" "}
      </Card.Body>
    </Card>
  );
}

export default Plan;
