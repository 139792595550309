import { Button, Card, Col, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import MotivationText from "./MotivationText";
import { QrReader } from "react-qr-reader";
import QRReader from "../../Reader/QRReader";
import ReactApexChart from "react-apexcharts";
import fetcher from "../../../utils/Fetcher";
import Plan from "./Plan";

export default function Welcome({ name, company, role, setFilter }) {
  const motivationText = MotivationText;
  const [randomNumber, setRandomNumber] = useState(0);
  const [randomNumberEmoji, setRandomNumberEmoji] = useState(0);
  const [qrData, setQrData] = useState(null);
  const [openReader, setOpenReader] = useState(false);
  const [inputValue, setInputValue] = useState("");
  //update random number every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setRandomNumber(Math.floor(Math.random() * motivationText.length));
      setRandomNumberEmoji(Math.floor(Math.random() * emoiji.length));
    }, 12000);
    return () => clearInterval(interval);
  }, []);
  const emoiji = [
    "bi bi-heart-fill text-danger",
    "bi bi-hand-index-fill text-primary",
    "bi bi-fire text-warning",
    "bi bi-hand-thumbs-up-fill text-primary",
    "bi bi-rocket-takeoff-fill text-primary",
  ];

  function handleInputChange(e) {
    setFilter(e.target.value);
    setInputValue(e.target.value);
  }

  return (
    /* <Row>
                                                                       {/!* <Col xxl={5} xl={12} lg={12} md={12} sm={12}>*!/}*/
    <Card className={"p-2"}>
      <Card.Body>
        <Row
          style={{
            /*  backgroundColor: "red",*/
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Col xl={5} lg={5} md={5} sm={7}>
            <div className="text-justified align-items-center">
              <h3 className="text-dark font-weight-semibold mb-2 mt-0">
                Hi, Welcome Back <span className="text-primary">{name}! </span>{" "}
              </h3>{" "}
              <Row>
                <p className="text-dark tx-14 mb-3 lh-3">
                  Your role is {role} for{" "}
                  <span className="text-primary">
                    {company?.map((item, index) => {
                      let name = item.replace(/_/g, " ");
                      return index === company.length - 1 ? name : name + ", ";
                    })}
                  </span>
                </p>
              </Row>
              <Row
                style={{
                  justifyContent: "space-between",
                }}
              >
                {/*    <p>{motivationText[randomNumber].text}</p>{" "}*/}
                <Card className={"mg-b-0"}>
                  <Card.Body className="p-0 mg-0">
                    <div className="input-group">
                      <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Search anything..."
                        onChange={handleInputChange}
                        value={inputValue}
                      />
                      <span className="input-group-append">
                        <Button
                          variant=""
                          className="btn btn-secondary"
                          type="button"
                          onClick={() => setOpenReader(!openReader)}
                        >
                          <i className={"bi bi-qr-code-scan"} />
                        </Button>

                        <Button
                          variant=""
                          className="btn btn-primary"
                          type="button"
                        >
                          <i className="bi bi-search" />
                        </Button>
                      </span>
                    </div>
                  </Card.Body>
                </Card>
              </Row>
            </div>
          </Col>
          {openReader && (
            <QRReader
              openReader={openReader}
              setOpenReader={setOpenReader}
              setQrData={setInputValue}
              setFilter={setFilter}
              closeQr={() => setOpenReader(false)}
            />
          )}

          <Col
            className={"wd-80p mg-l-20 px-1"}
            style={{ padding: "unset !important" }}
          >
            <p className={"wd-80p fadeInText"} id={"motivation"}>
              {" "}
              <i
                style={{ marginRight: "0.5rem" }}
                className={emoiji[randomNumberEmoji]}
              ></i>{" "}
              {motivationText[randomNumber].text}{" "}
              {/* <i
                style={{ margin: "0.5rem" }}
                className={emoiji[randomNumberEmoji]}
              ></i>*/}
            </p>{" "}
          </Col>

          <div classname="wd-50">
            <Button
              variant="primary"
              className="btn btn-primary btn-block"
              onClick={() => {
                window.location.href = "/locked";
              }}
            >
              <i className="bi bi-lock-fill" /> Lock
            </Button>
          </div>
        </Row>
      </Card.Body>
    </Card>

    /* {/!* </Col>*!/}
                                                                    </Row>*/
  );
}
