import React, { useEffect } from "react";
import { Button, ButtonGroup, Card, Col, Modal, Row } from "react-bootstrap";
import { getUser } from "../../../api/users/getUser.tsx";
import Welcome from "./Welcome";
import SafeCards from "../SafeCards/SafeCards";
import CostsModal from "../Modals/CostsModal";
import PendingSellDeals from "../PendingDeals/PendingSellDeals.tsx";
import PendingBuyDeals from "../PendingDeals/PendingBuyDeals.tsx";
import DealData from "../Deal/DealData.tsx";
import WorkerHistory from "../History/WorkerHistory.tsx";
import CookiesStore from "../../../utils/CookiesStore";
import WorkerReport from "../History/WorkerReport";
import ExchangeModal from "../Modals/ExchangeModal";
import Plan from "./Plan";

const WorkerDashboard = () => {
  const [user, setUser] = React.useState();
  const [costModalShow, setCostModalShow] = React.useState(false);
  const [deal, setDeal] = React.useState();
  const [dealType, setDealType] = React.useState();
  const [filter, setFilter] = React.useState("");
  const [exchangeModalShow, setExchangeModalShow] = React.useState(false);

  useEffect(() => {
    if (!user) {
      getUser().then((res) => {
        let userData = res.data;
        //  console.log(userData.companies);
        setUser(userData);
      });
    } else {
    }
  }, [user, user?.companies]);

  useEffect(() => {}, [user?.companies]);

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">DASHBOARD</span>
        </div>
        <div className="justify-content-center mt-2"></div>
      </div>
      {/* <!-- /breadcrumb --> */}

      {/* <!-- row --> */}
      <Row>
        <Col xxl={9} xl={12} lg={12} md={12} sm={12}>
          <Col xl={12} lg={12} md={12} sm={12} className="px-0">
            {" "}
            <Welcome
              name={user?.firstName + " " + user?.lastName}
              company={user?.companies}
              role={"SAFEMAN"}
              setFilter={setFilter}
            />
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              className="px-0"
              hidden={!deal}
            >
              <Card>
                <Card.Header
                  className={
                    "d-flex justify-content-between align-items-center"
                  }
                >
                  <Card.Title>Deal</Card.Title>
                  <i
                    className={"fe fe-close"}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setDeal(null)}
                  />
                </Card.Header>
                <Card.Body>
                  {deal ? (
                    <DealData dealSearchData={deal} type={dealType} />
                  ) : null}
                </Card.Body>
              </Card>
            </Col>
          </Col>

          <Plan />
          <Col xl={12} lg={12} md={12} sm={12} className="px-0">
            <Row>
              <Col sm={12} lg={12} xl={6}>
                <Card className="custom-card overflow-hidden">
                  <Card.Header className=" border-bottom-0 d-flex">
                    <h3 className="card-title mb-2 ">PENDING CASH</h3>
                    <div className="card-options ms-auto"></div>
                  </Card.Header>
                  <Card.Body>
                    <PendingSellDeals
                      company={user?.companies}
                      openDeal={setDeal}
                      dealType={setDealType}
                      filter={filter}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} lg={12} xl={6}>
                <Card>
                  <Card.Header className=" border-bottom-0 d-flex">
                    <div>
                      <h3 className="card-title mb-2">PENDING CRYPTO</h3>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <PendingBuyDeals
                      company={user?.companies}
                      openDeal={setDeal}
                      dealType={setDealType}
                      statuses={"paid,created"}
                      filter={filter}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          {/*<Col xl={12} lg={12} md={12} sm={12} className="px-0">
            <Row>
              <Col sm={12} lg={12} xl={4}></Col>
              <Col lg={12} xl={4} md={12}></Col>
              <Col lg={12} xl={4} md={12}></Col>
            </Row>
          </Col>*/}
        </Col>
        <Col xs={12} lg={12} md={12} xl={12} xxl={3}>
          <Card className="overflow-hidden">
            <Card.Header
              className="pb-1"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Modal
                show={costModalShow}
                onHide={() => setCostModalShow(false)}
                centered="true"
                id=""
              >
                <Modal.Header>
                  <h6 className="modal-title">Add Cost</h6>
                  <Button
                    variant=""
                    type="button"
                    onClick={() => setCostModalShow(false)}
                  >
                    <span aria-hidden="true" className="text-dark">
                      <i className="fe fe-close"></i>
                    </span>
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <Col>
                    <CostsModal
                      closeModal={setCostModalShow}
                      company={user?.companies}
                    />
                  </Col>
                </Modal.Body>
              </Modal>
              <h3 className="card-title mb-2">SAFE</h3>

              <Modal
                show={exchangeModalShow}
                onHide={() => setExchangeModalShow(false)}
                centered="true"
                id=""
              >
                <Modal.Header>
                  <h6 className="modal-title">Exchange currency</h6>
                  <Button
                    variant=""
                    type="button"
                    onClick={() => setExchangeModalShow(false)}
                  >
                    <span aria-hidden="true" className="text-dark">
                      <i className="fe fe-close"></i>
                    </span>
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <Col>
                    <ExchangeModal closeModal={setExchangeModalShow} />
                  </Col>
                </Modal.Body>
              </Modal>
            </Card.Header>
            <div
              className={"d-flex mg-b-10"}
              style={{ justifyContent: "space-evenly" }}
            >
              <div>
                <Button
                  variant=""
                  className="btn btn-primary"
                  type="button"
                  onClick={() => {
                    setCostModalShow(true);
                  }}
                >
                  Record cost
                </Button>
              </div>
              <div>
                <Button
                  variant=""
                  className="btn btn-warning"
                  type="button"
                  onClick={() => {
                    setExchangeModalShow(true);
                  }}
                >
                  Record exchange
                </Button>
              </div>
            </div>
            <Card.Body className=" p-0 customers mt-1">
              <SafeCards company={user?.companies[0]} />
            </Card.Body>
          </Card>

          <Card className="overflow-hidden">
            <Card.Header className=" pb-1">
              <h3 className="card-title mb-2">Recent Transactions</h3>
            </Card.Header>
            <Card.Body className=" p-0 customers mt-1">
              <WorkerHistory filter={filter} />
            </Card.Body>
          </Card>
          <Card className="overflow-hidden">
            <Card.Header className=" pb-1">
              <h3 className="card-title mb-2">Report</h3>
            </Card.Header>
            <Card.Body className=" p-0 customers mt-1">
              <WorkerReport />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!-- row  --> */}

      {/* <!-- /row --> */}
    </div>
  );
};
WorkerDashboard.propTypes = {};

WorkerDashboard.defaultProps = {};

export default WorkerDashboard;
