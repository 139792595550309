import fetcher from "../../utils/Fetcher";

export default async function recordCost(data, company) {
  // console.log(data);
  return await fetcher(
    "post",
    "/companies/" + company + "/safes/addCost",
    {
      amount: data.amount,
      currencyCode: data.currencyCode,
      description: data.finalDescription,
    },
    {}
  );
}
