import { Button, Card, Col, Modal } from "react-bootstrap";
import CostsModal from "../Modals/CostsModal";
import SafeCards from "./SafeCards";
import React from "react";

function CryptoCards({ costModalShow, setCostModalShow, user }) {
  return (
    <Card className="overflow-hidden">
      <Card.Header
        className="pb-1"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Modal
          show={costModalShow}
          onHide={() => setCostModalShow(false)}
          centered="true"
          id=""
        >
          <Modal.Header>
            <h6 className="modal-title">Add Cost</h6>
            <Button
              variant=""
              type="button"
              onClick={() => setCostModalShow(false)}
            >
              <span aria-hidden="true" className="text-dark">
                <i className="fe fe-close"></i>
              </span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Col>
              <CostsModal
                closeModal={setCostModalShow}
                company={user?.companies}
                isSender={user?.roles[0].includes("SENDER")}
              />
            </Col>
          </Modal.Body>
        </Modal>
        <h3 className="card-title mb-2">SAFES</h3>
        <Button
          variant=""
          className="btn btn-primary"
          type="button"
          onClick={() => {
            setCostModalShow(true);
          }}
        >
          Record cost
        </Button>
      </Card.Header>
      <Card.Body className=" p-0 customers mt-1">
        {" "}
        {user?.companies?.map((company) => (
          <SafeCards
            key={company}
            company={company}
            ignoreCash={user?.roles[0].includes("SENDER")}
          />
        ))}{" "}
        {/* <SafeCards company={user?.companies[0]} />*/}
      </Card.Body>
    </Card>
  );
}
export default CryptoCards;
