import { useEffect, useState } from "react";
// @ts-ignore
import {
  Deal,
  getPendingBuyDeals,
  getPendingSellDeals,
  updateSellDealStatus,
} from "../../../api/deals/getDeals.tsx";
import { Button, Modal, Table } from "react-bootstrap";
import { SmallLoader } from "../Loaders";
import { updateDealStatus } from "../../../api/deals/getDeals";

// @ts-ignore
function PendingSellDeals({ company, openDeal, dealType, filter }) {
  const [deals, setDeals] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [readyLoading, setReadyLoading] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setLoading(true);
      getPendingSellDeals(company) // @ts-ignore
        .then((res) => {
          //  console.log(res); // @ts-ignore
          setDeals(res.data as Deal[]);
          setLoading(false);
        }) // @ts-ignore
        .catch((error) => {
          setError(error.response.data.message);
          setLoading(false);
        });
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    getPendingSellDeals(company) // @ts-ignore
      .then((res) => {
        //   console.log(res); // @ts-ignore
        setDeals(res.data as Deal[]);
        setLoading(false);
      }) // @ts-ignore
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  }, [loading]);

  function ready(dealNumber: string) {
    setReadyLoading(true);
    updateDealStatus(dealNumber, "ready") // @ts-ignore
      .then((res) => {
        //   console.log(res);
        setLoading(false);
        setReadyLoading(false);
        setUpdateStatus(!updateStatus);
      }) // @ts-ignore
      .catch((error) => {
        setError(error.response.data.message.errorDescription);
        setLoading(false);
      });
  }

  useEffect(() => {
    //  console.log(filter);
    if (!filter) {
      //  console.log("filter empty");
      setFilteredList([]);
      setLoading(true);
    } else {
      setLoading(false);
      let lowerFilter = filter.toLowerCase();
      // console.log(filteredList.length);
      setFilteredList(
        deals.filter((el) => {
          return (
            el?.dealNumber?.toLowerCase()?.includes(lowerFilter) ||
            el?.dealCode?.toLowerCase()?.includes(lowerFilter)
          );
        })
      );
    }
  }, [filter, filteredList.length]);

  return (
    <div>
      {error ? (
        <div className="alert alert-danger mg-t-10 mg-x-10" role="alert">
          {error}
        </div>
      ) : null}
      <Table className={"list-lg-group list-group-flush"}>
        <thead>
          <tr style={{ fontSize: "12px" }}>
            <th style={{ fontSize: "12px" }}>Deal number</th>
            <th style={{ fontSize: "12px" }}>Deal code</th>
            <th style={{ fontSize: "12px" }}>Fiat value</th>
            <th style={{ fontSize: "12px" }}>Status</th>
            <th style={{ fontSize: "12px" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredList.length > 0
            ? filteredList.map((d: Deal, index: number) => {
                return (
                  <tr
                    key={index}
                    className={"tx-12"}
                    style={{ backgroundColor: "" }}
                  >
                    <th>
                      <p className={"bg-success-gradient"}>{d.dealNumber}</p>
                    </th>
                    <th>
                      <code style={{ letterSpacing: "0.25rem" }}>
                        {d.dealCode}{" "}
                      </code>
                    </th>
                    <th>
                      {" "}
                      {d.fiatValue} {d.fiatCode.toUpperCase()}
                    </th>
                    <th>
                      {" "}
                      <span
                        className={
                          d.status === "paid"
                            ? "badge bg-secondary me-1 my-2"
                            : "badge bg-primary me-1 my-2"
                        }
                      >
                        {d.status === "paid" ? "PAID" : "READY"}
                      </span>{" "}
                    </th>

                    <th>
                      {" "}
                      {d.status === "paid" ? (
                        <div className={"d-flex flex-row"}>
                          {company[0] === "VISA_MASTERCARD" ? null : (
                            <Button
                              className="btn-warning btn-md mg-r-2"
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                ready(d.dealNumber);
                              }}
                            >
                              {readyLoading ? <SmallLoader /> : "Ready"}
                            </Button>
                          )}
                          <Button
                            className="btn-info btn-md btn-block mg-l-2"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              openDeal(d.dealCode);
                              dealType("sell");
                            }}
                          >
                            Info
                          </Button>
                        </div>
                      ) : (
                        <Button
                          className="btn-info btn-md btn-block"
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            openDeal(d.dealCode);
                            dealType("sell");
                          }}
                        >
                          Info
                        </Button>
                      )}{" "}
                    </th>
                  </tr>
                );
              })
            : deals.map((d: Deal, index: number) => {
                return (
                  <tr key={index} className={"tx-12"}>
                    <th>{d.dealNumber}</th>
                    <th>
                      <code style={{ letterSpacing: "0.25rem" }}>
                        {d.dealCode}{" "}
                      </code>
                    </th>
                    <th>
                      {" "}
                      {d.fiatValue} {d.fiatCode.toUpperCase()} {d.remainderBalance ? '(+' + d.remainderBalance?.amount + ' ' + d.remainderBalance?.code.toUpperCase() + ')' : ''}
                    </th>
                    <th>
                      {" "}
                      <span
                        className={
                          d.status === "paid"
                            ? "badge bg-secondary me-1 my-2"
                            : "badge bg-primary me-1 my-2"
                        }
                      >
                        {d.status === "paid" ? "PAID" : "READY"}
                      </span>{" "}
                    </th>

                    <th>
                      {" "}
                      {d.status === "paid" ? (
                        <div className={"d-flex flex-row"}>
                          {company !== undefined &&
                          company.length > 0 &&
                          company[0] === "VISA_MASTERCARD" ? null : (
                            <Button
                              className="btn-warning btn-md mg-r-2"
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                ready(d.dealNumber);
                              }}
                            >
                              {readyLoading ? <SmallLoader /> : "Ready"}
                            </Button>
                          )}
                          <Button
                            className="btn-info btn-md btn-block mg-l-2"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              openDeal(d.dealCode);
                              dealType("sell");
                            }}
                          >
                            Info
                          </Button>
                        </div>
                      ) : (
                        <Button
                          className="btn-info btn-md btn-block"
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            openDeal(d.dealCode);
                            dealType("sell");
                          }}
                        >
                          Info
                        </Button>
                      )}{" "}
                    </th>
                  </tr>
                );
              })}
        </tbody>
      </Table>
      <Modal.Footer>
        <span>Total deals: {loading ? <SmallLoader /> : deals.length}</span>
        <span>Pending deals: {deals.length}</span>
      </Modal.Footer>
    </div>
  );
}

export default PendingSellDeals;
