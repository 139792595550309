import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { useEffect, useState } from "react";
//@ts-ignore
import { SmallLoader } from "../Loaders";
import { Button, Modal, Table } from "react-bootstrap";

// @ts-ignore
import arrowRight from "../../../assets/img/svg/chevrons-right.svg"; // @ts-ignore
import { getWorkerReport } from "../../../api/netDocs/getWorkerReport";

function WorkerReport() {
  const [history, setHistory] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const data = {
    deals: [
      {
        dealNumber: "CT12001",
        gelEquivalent: 1234,
        generatedAt: 123435345,
      },
    ],
    totalDeals: 12,
    totalIncomeAmount: 1000,
  };
  useEffect(() => {
    setHistory(data);
    setLoading(true);
    getWorkerReport() // @ts-ignore
      .then((res) => {
        //   console.log(res); // @ts-ignore
        setHistory(res.data);

        setLoading(false);
      }) // @ts-ignore
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  }, []);

  return (
    <ListGroup
      className="list-lg-group list-group-flush"
      style={{ maxHeight: "40rem", overflowY: "scroll", cursor: "default" }}
    >
      {loading ? <SmallLoader /> : null}
      {error ? (
        <div className="alert alert-danger mg-t-10 mg-x-10" role="alert">
          {error}
        </div>
      ) : null}
      <Link to="#" className="border-0" style={{ cursor: "default" }}>
        {history?.deals?.map((d: any, index: number) => {
          let color = "primary";
          return (
            <ListGroupItem
              className="list-group-item-action border-0"
              key={index}
            >
              <div className="media mt-0">
                <span className="me-3 bg-primary-transparent text-primary transaction-icon">
                  <img
                    className={"my-arrows"}
                    src={arrowRight}
                    alt="arrow-right"
                  />
                </span>

                <div className="media-body">
                  <div className="d-flex align-items-center">
                    <div className="mt-0">
                      <h5 className="mb-1 tx-13 font-weight-sembold text-dark">
                        <span className="me-3">{d?.dealNumber}</span>
                      </h5>
                      <div
                        style={{
                          fontSize: "12px",
                        }}
                      ></div>
                      <p className="mb-0 tx-12 text-muted">
                        {new Date(d?.generatedAt).toLocaleString()}
                      </p>
                    </div>
                    <span className="ms-auto wd-50p tx-12">
                      <span
                        className={"text-" + color + " tx-11 text-end d-block"}
                        style={{ textTransform: "uppercase" }}
                      >
                        INCOME
                      </span>
                      <span
                        className={
                          "float-end text-" + color + " font-weight-semibold"
                        }
                        style={{ textTransform: "uppercase" }}
                      >
                        {d?.gelEquivalent}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </ListGroupItem>
          );
        })}
      </Link>
      <Modal.Footer className={"pd-20"}>
        Total Deals: {history?.totalDeals}
        <br />
        Total Income : {history?.totalIncomeAmount}
      </Modal.Footer>
    </ListGroup>
  );
}

export default WorkerReport;
