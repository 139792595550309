import { Button, Form, FormGroup, Modal } from "react-bootstrap";
import React from "react";
import { SmallLoader } from "../Loaders";
import {
  closeBuyDeal,
  updateBuyDealStatus,
  updateSellDealStatus,
  updateVisaSellDealStatus,
} from "../../../api/deals/getDeals.tsx";
import { updateReward } from "../../../api/rewards/getRewards.tsx";

function DealModal({ closeModal, deal, status, type, isSender }) {
  const [confirm, setConfirm] = React.useState(false);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  function handleSubmit(event) {
    setLoading(true);
    event.preventDefault();
    if (type === "buy") {
      if (isSender) {
        closeBuyDeal(deal)
          .then((response) => {
            if (response.status === 200) {
              closeModal(false);
              setLoading(false);
              window.location.reload();
            }
          })
          .catch((error) => {
            //  console.log(error.message);
            setError(error.message);
            setLoading(false);
          });
      } else {
        updateBuyDealStatus(deal, status)
          .then((response) => {
            if (response.status === 200) {
              closeModal(false);
              setLoading(false);
              window.location.reload();
            }
          })
          .catch((error) => {
            //  console.log(error.message);
            setError(error.message);
            setLoading(false);
          });
      }
    } else if (type === "sell" && isSender) {
      updateVisaSellDealStatus(deal, status)
        .then((response) => {
          if (response.status === 200) {
            closeModal(false);
            setLoading(false);
            window.location.reload();
          }
        })
        .catch((error) => {
          //  console.log(error.message);
          setError(error.message);
          setLoading(false);
        });
    } else if (type === "sell") {
      updateSellDealStatus(deal, status)
        .then((response) => {
          if (response.status === 200) {
            closeModal(false);
            setLoading(false);
            window.location.reload();
          }
        })
        .catch((error) => {
          //  console.log(error.message);
          setError(error.message);
          setLoading(false);
        });
    } else if (type === "reward") {
      updateReward(deal, status)
        .then((response) => {
          if (response.status === 200) {
            closeModal(false);
            setLoading(false);
            window.location.reload();
          }
        })
        .catch((error) => {
          //  console.log(error.message);
          setError(error.message);
          setLoading(false);
        });
    }
  }

  function handleConfirm() {
    setConfirm(!confirm);
  }

  return (
    <Form className="form-horizontal">
      <Modal.Title id="contained-modal-title-lg">
        <h5 className={"swal2-title"} style={{ marginBottom: "2rem" }}>
          Are you sure you want to set this{" "}
          {type === "reward" ? "reward" : "deal"} {status}?
        </h5>
      </Modal.Title>
      <FormGroup className="form-group mb-0 justify-content-end">
        <div className="checkbox">
          <div className="custom-checkbox custom-control">
            <Form.Control
              aria-required={true}
              type="checkbox"
              data-checkboxes="mygroup"
              className="custom-control-input"
              id="checkbox-2"
              required={true}
              onChange={handleConfirm}
            />
            <Form.Label
              htmlFor="checkbox-2"
              className="custom-control-label mt-1"
            >
              Yes I checked everything and I am sure that everything is correct.
              I am acknowledge and agree with{" "}
              <u className={"tx-semibold text-warning"}>
                Rules, Bonuses and Penalties policy
              </u>
            </Form.Label>
          </div>
        </div>
      </FormGroup>
      <FormGroup className="form-group mb-0 mt-3 justify-content-start">
        <div>
          {error !== "" ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : (
            ""
          )}
          <Modal.Footer>
            <Button
              disabled={error !== ""}
              variant=""
              type="submit"
              className={
                !confirm ? "btn me-2 btn-primary disabled" : "btn btn-primary"
              }
              onClick={(e) => {
                if (confirm) {
                  handleSubmit(e);
                }
              }}
            >
              {loading ? <SmallLoader /> : "Confirm"}
            </Button>
            <Button
              variant="secondary"
              onClick={(e) => {
                closeModal(false);
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </FormGroup>
    </Form>
  );
}

export default DealModal;
