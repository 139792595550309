import React, { useState } from "react";
import { QrReader } from "react-qr-reader";

const QRReader = ({ setQrData, closeQr, setFilter }) => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [qrFound, setQrFound] = useState(false);

  const handleScan = (data) => {
    if (data) {
      setResult(data);
      setQrData(data.text);
      setFilter(data.text);
      setQrFound(true);
      closeQr();
    }
  };

  const handleError = (err) => {
    setError(err);
    setQrFound(false);
  };

  return (
    <div style={{ width: "300px", height: "300px", backgroundColor: "#000" }}>
      <QrReader
        style={{ width: "100%", height: "100%" }}
        onError={handleError}
        onResult={handleScan}
      />
      <p style={{ color: "#fff", marginTop: "20px" }}>
        Align QR code in the frame to scan
      </p>
      {result && (
        <p style={{ color: "#fff", marginTop: "20px" }}>
          QR code data: {result.text}
        </p>
      )}
      {error && (
        <p style={{ color: "#fff", marginTop: "20px" }}>
          Error: {error.message}
        </p>
      )}
    </div>
  );
};

export default QRReader;
