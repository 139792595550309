import fetcher from "../../utils/Fetcher";

export class Reward {
  requestNumber: string;
  createdAt: string;
  completedAt: string;
  totalAmount: string;
  wallet: string;
  status: string;
}

export async function getPendingRewards() {
  return await fetcher("get", "/rewards?status=CREATED", {}, {});
}
export async function updateReward(deal: string, status: string) {
  return await fetcher("put", `/rewards/${deal}/paid`, {}, {});
}
