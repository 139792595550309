import React, { useEffect, useState } from "react";
// @ts-ignore
// @ts-ignore
import {
  Deal,
  declineDeal,
  getDeal,
  getPhoneNumber,
  updateDealWallet,
  updatePaidAmount,
} from "../../../../api/deals/getDeals.tsx";
import { LargeLoader, SmallLoader } from "../../Loaders";
import {
  Button,
  Col,
  Form,
  Modal,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DealModal from "../../Modals/DealModal.jsx";
import { API_URL } from "../../../../api/Constants";
import CookiesStore from "../../../../utils/CookiesStore";
import QRCode from "react-qr-code";
// @ts-ignore
function DealData({ dealSearchData, type, isSender }) {
  const [dealData, setDealData] = useState(new Deal());
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editWallet, setEditWallet] = useState(false);
  const [wallet, setWallet] = useState("");
  const [editWalletLoading, setEditWalletLoading] = useState(false);
  const [editWalletError, setEditWalletError] = useState(null);
  const [amount, setAmount] = useState(0);
  const [editAmount, setEditAmount] = useState(false);
  const [editAmountLoading, setEditAmountLoading] = useState(false);
  const [editAmountError, setEditAmountError] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [userPhoto, setUserPhoto] = useState("");
  const [userPhotoLoading, setUserPhotoLoading] = useState(true);
  const [userPhotoError, setUserPhotoError] = useState(null);
  const [documentImage, setDocumentImage] = useState("");
  const [documentLoading, setDocumentLoading] = useState(false);
  const [documentError, setDocumentError] = useState(null);
  const [currencyCode, setCurrencyCode] = React.useState("");
  const [areYouSure, setAreYouSure] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    getDeal(dealSearchData, type) // @ts-ignore
      .then((res) => {
        setDealData(res.data as Deal);
        setLoading(false);
        setEditWallet(false);
        setEditAmount(false);
      }) // @ts-ignore
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  }, [dealSearchData, type, editWalletLoading, editAmountLoading, modalShow]);

  useEffect(() => {
    if (dealData.userId) {
      const jwtToken = CookiesStore.getJwtToken();
      fetch(API_URL + "/users/" + dealData.userId + "/photos/selfie", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + jwtToken,
          "Content-Type": "image/jpeg",
        },
      })
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          //  console.log(buffer);
          const blob = new Blob([buffer], { type: "image/jpeg" });
          const url = URL.createObjectURL(blob);
          setUserPhoto(url);
          setUserPhotoLoading(false);
        })
        .catch((error) => {
          setUserPhotoError(error.response.data.message);
          setUserPhotoLoading(false);
        });
    }
  }, [dealData]);
  function decline(dealNumber: string) {
    setLoading(true);
    declineDeal(dealNumber) // @ts-ignore
      .then((res) => {
        //  console.log(res);
        // @ts-ignore
        setLoading(false);
        window.location.reload();
      })
      .catch((error: any) => {
        //  console.log(error);
        setError(error.response.data.message);
      });
  }
  function loadDocumentImage() {
    if (dealData.userId) {
      setDocumentLoading(true);
      const jwtToken = CookiesStore.getJwtToken();
      fetch(API_URL + "/users/" + dealData.userId + "/photos/document", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + jwtToken,
          "Content-Type": "image/jpeg",
        },
      })
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          //  console.log(buffer);
          const blob = new Blob([buffer], { type: "image/jpeg" });
          const url = URL.createObjectURL(blob);
          setDocumentImage(url);
          setDocumentLoading(false);
        })
        .catch((error) => {
          setDocumentError(error.response.data.message);
          setDocumentLoading(false);
        });
    }
  }

  function updateWallet() {
    // console.log(wallet);
    updateDealWallet(dealData.dealNumber, wallet) // @ts-ignore
      .then((res) => {
        //  console.log(res);
        setEditWalletLoading(false);
        setEditWallet(false);
        setError(null);
      }) // @ts-ignore
      .catch((error) => {
        //  console.log(error);
        setEditWalletLoading(false);
        setEditWalletError(error.response.data.message.errorDescription);
      });
  }

  function updateAmount() {
    //  console.log(amount);
    setEditAmountLoading(true);
    updatePaidAmount(dealData.dealNumber, amount, currencyCode) // @ts-ignore
      .then((res) => {
        // console.log(res);
        setEditAmountLoading(false);
        setEditAmount(false);
        setEditAmountError(null);
      }) // @ts-ignore
      .catch((error) => {
        //  console.log(error);
        setEditAmountLoading(false);
        setEditAmountError(error.response.data.message.errorDescription);
      });
  }
  // @ts-ignore
  function handleCurrencyChange(event) {
    setCurrencyCode(event.target.value);
  }
  // @ts-ignore
  function loadPhoneNumber() {
    if (dealData.userId) {
      getPhoneNumber(dealData.userId).then((res: any) => {
        setPhoneNumber(res.data);
      });
    }
  }

  // @ts-ignore
  return (
    <div>
      {loading ? (
        <LargeLoader />
      ) : (
        <div>
          <div className="main-content-body main-content-body-contacts card custom-card">
            <div className="main-contact-info-header pt-2">
              <div className="media" style={{ alignItems: "stretch" }}>
                <div className="main-img-user">
                  {userPhotoError ? (
                    <div className="avatar avatar-md brround">
                      <span className="avatar-title bg-primary rounded-circle">
                        {dealData.firstName.charAt(0)}
                      </span>
                    </div>
                  ) : null}
                  {userPhotoLoading ? (
                    <SmallLoader />
                  ) : (
                    <img
                      id={"avatar_img"}
                      alt="avatar"
                      src={userPhoto}
                      style={{
                        transition: "all 0.3s ease 0s",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        position: "relative",
                        zIndex: 100,
                      }}
                      onMouseMove={(e) => {
                        // @ts-ignore
                        let el = document.getElementById("avatar_img"); // @ts-ignore
                        el.style.transition = "all 0.3s ease 0s"; // @ts-ignore
                        el.style.transform = "translateX(125%) scale(4)";
                      }}
                      onMouseOut={(e) => {
                        // @ts-ignore
                        let el = document.getElementById("avatar_img"); // @ts-ignore
                        el.style.transform = "scale(1)"; // @ts-ignore
                        el.style.transform = "translate(0, 0)";
                      }}
                    />
                  )}
                </div>
                <div className="media-body">
                  <h5 style={{ letterSpacing: "0.25rem" }}>
                    {dealData?.dealNumber}
                  </h5>{" "}
                  <p>{dealData?.firstName + " " + dealData?.lastName}</p>
                  <Nav className="contact-info">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Call</Tooltip>}
                    >
                      <a
                        href="#"
                        className="contact-icon border tx-inverse"
                        data-bs-toggle="tooltip"
                        title="Call"
                        onClick={() => {
                          loadPhoneNumber();
                        }}
                      >
                        <i className="fe fe-phone"></i>
                      </a>
                    </OverlayTrigger>
                    {phoneNumber && (
                      <div className={"d-flex flex-row mg-t-20"}>
                        <p className={"mg-b-0"}>{phoneNumber}</p>
                      </div>
                    )}
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Load document</Tooltip>}
                    >
                      <a
                        href="#"
                        className="contact-icon border tx-inverse"
                        data-bs-toggle="tooltip"
                        title="Document"
                        onClick={() => loadDocumentImage()}
                      >
                        {documentLoading ? (
                          <SmallLoader />
                        ) : (
                          <i className="fe fe-user"></i>
                        )}
                      </a>
                    </OverlayTrigger>
                  </Nav>
                  {documentImage ? (
                    <div className={"d-flex flex-row mg-t-20"}>
                      <img
                        id={"document_img"}
                        alt="document"
                        src={documentImage}
                        style={{
                          transition: "all 0.3s ease 0s",
                          width: "100px",
                          height: "auto",
                          aspectRatio: "2 / 1",
                          borderRadius: "1rem",
                          objectFit: "cover",
                          position: "relative",
                          marginLeft: "none",
                          zIndex: 100,
                        }}
                        onMouseMove={(e) => {
                          // @ts-ignore
                          let el = document.getElementById("document_img"); // @ts-ignore
                          el.style.transition = "all 0.3s ease 0s"; // @ts-ignore
                          el.style.transform = "translateX(100%) scale(3)";
                          el.style.width = "100%";
                          el.style.height = "100%";
                          el.style.aspectRatio = "unset";
                        }}
                        onMouseOut={(e) => {
                          // @ts-ignore
                          let el = document.getElementById("document_img"); // @ts-ignore
                          el.style.transform = "scale(1)"; // @ts-ignore
                          el.style.transform = "translate(0, 0)";
                          el.style.width = "100px";
                          el.style.height = "auto";
                          el.style.aspectRatio = "2 / 1";
                        }}
                      />
                      <Button
                        variant="outline-danger"
                        className={"btn-sm mg-10"}
                        onClick={() => setDocumentImage(null)}
                        style={{
                          margin: "1rem",
                          alignSelf: "start",
                          padding: "0.5rem 1rem",
                        }}
                      >
                        <i
                          className={"fe fe-close"}
                          style={{ margin: "0.2rem 0.2rem 0.2rem 0.2rem" }}
                        ></i>
                      </Button>
                    </div>
                  ) : null}
                </div>
                <div className="media-body mg-l-15">
                  <h5>{dealData?.status?.toUpperCase()}</h5>
                  <p>Status</p>
                </div>
                {type === "sell" ? (
                  <div className="media-body mg-l-15">
                    <h5 style={{ letterSpacing: "0.25rem" }}>
                      {dealData?.dealCode}
                    </h5>
                    <p>Code</p>
                  </div>
                ) : (
                  ""
                )}
                <div className="media-body d-flex flex-column">
                  {/*<div className={"d-flex flex-row"}>
                    <h5 style={{ textTransform: "uppercase" }}>
                      {dealData?.fiatValue + " " + dealData?.fiatCode}
                    </h5>

                    {type === "buy" ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Edit amount</Tooltip>}
                      >
                        <Button
                          variant="outline-primary"
                          className="btn-icon mg-l-15"
                          style={{
                            height: "30px",
                            width: "30px",
                            padding: "0px",
                          }}
                        >
                          <i className="fe fe-pencil"></i>
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      ""
                    )}
                  </div>*/}
                  <div className="tx-medium d-flex flex-row">
                    {
                      <h5 style={{ textTransform: "uppercase" }}>
                        console.log(dealData?.remainderBalance);
                        {dealData?.fiatValue + " " + (dealData?.fiatCode?.toUpperCase() || '') + (dealData?.remainderBalance ? "(+" + dealData.remainderBalance.amount + " " + dealData.remainderBalance.code.toUpperCase() + ")" : "")}
                      </h5>
                    }{" "}
                    {type === "buy" &&
                    !editAmount &&
                    dealData?.status !== "closed" ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Edit amount</Tooltip>}
                      >
                        <Button
                          variant="outline-primary"
                          className="btn-icon mg-l-15"
                          style={{
                            height: "30px",
                            width: "30px",
                            padding: "0px",
                          }}
                          onClick={() => setEditAmount(!editAmount)}
                        >
                          <i className="fe fe-pencil"></i>
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      ""
                    )}
                    {type === "buy" && editAmount ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Cancel</Tooltip>}
                      >
                        <Button
                          variant="outline-secondary"
                          className="btn-icon mg-l-5"
                          style={{
                            padding: "0px",
                          }}
                          onClick={() => {
                            setEditAmount(!editAmount);
                            setEditAmountLoading(false);
                            setAmount(0);
                            setEditAmountError(null);
                          }}
                        >
                          <i className="fe fe-close"></i>
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      ""
                    )}
                  </div>
                  <p>Amount</p>
                </div>
                <div className="">
                  <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    //@ts-ignore
                    centered="true"
                    id=""
                  >
                    <Modal.Header>
                      <h6 className="modal-title">Update Deal</h6>
                      <Button
                        variant=""
                        type="button"
                        onClick={() => setModalShow(false)}
                      >
                        <span aria-hidden="true" className="text-dark">
                          <i className="fe fe-close"></i>
                        </span>
                      </Button>
                    </Modal.Header>
                    <Modal.Body>
                      <Col>
                        <DealModal
                          closeModal={setModalShow}
                          deal={
                            type === "buy"
                              ? dealData?.dealNumber
                              : dealData?.dealCode
                          }
                          status={
                            isSender
                              ? "closed"
                              : type === "buy"
                              ? "paid"
                              : "closed"
                          }
                          type={type}
                          isSender={isSender}
                        />
                      </Col>
                    </Modal.Body>
                  </Modal>

                  {isSender ? (
                    <Button
                      variant="primary"
                      className={
                        dealData?.status !== "paid"
                          ? "btn btn-primary disabled"
                          : "btn btn-primary"
                      }
                      disabled={dealData?.status !== "paid" && type === "buy"}
                      onClick={() => {
                        setModalShow(true);
                      }}
                    >
                      Close Deal
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      className={
                        dealData?.status !== "created" && type === "buy"
                          ? "btn btn-primary disabled"
                          : "btn btn-primary"
                      }
                      disabled={
                        dealData?.status !== "created" && type === "buy"
                      }
                      onClick={() => {
                        setModalShow(true);
                      }}
                    >
                      {type === "sell"
                        ? "Close deal"
                        : dealData?.status === "paid"
                        ? "PAID"
                        : "Accept deal"}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="main-contact-info-body p-4">
              {
                <div className="media-list pb-0">
                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Card number</label>
                        <span className="tx-medium">
                          {dealData?.cardNumber}
                        </span>
                      </div>
                      <div>
                        <label>Card holder</label>
                        <span className="tx-medium">
                          {dealData?.cardHolderFirstName +
                            " " +
                            dealData?.cardHolderLastName}
                        </span>
                      </div>
                      <div>
                        <label>Card expiration date</label>
                        <span className="tx-medium">
                          {dealData?.cardExpiredDate}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {/* <div>
                <h6>Created at</h6>
                <p>{new Date(dealData?.createdAt).toLocaleString()}</p>
                <h6>Paid At :</h6>
                <p>{new Date(dealData?.paidAt).toLocaleString()}</p>
              </div>*/}
              <div className="media-list pb-0">
                <div className="media">
                  <div className="media-body">
                    <div>
                      <label>Created at</label>{" "}
                      <span className="tx-medium">
                        {new Date(dealData?.createdAt).toLocaleString()}
                      </span>
                    </div>
                    <div>
                      <label>Paid At :</label>{" "}
                      <span className="tx-medium">
                        {new Date(dealData?.paidAt).toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="media">
                  <div className="media-body">
                    <div>
                      <label>Original crypto : </label>{" "}
                      <span
                        className="tx-medium"
                        style={{ textTransform: "uppercase" }}
                      >
                        {dealData?.originalCryptoValue +
                          " " +
                          dealData?.cryptoCode +
                          " " +
                          dealData?.network}
                      </span>
                    </div>
                    <div>
                      <label>
                        {type === "buy" && !isSender
                          ? "Payout amount "
                          : "Paid amount "}
                        :
                      </label>
                      <span
                        className="tx-medium"
                        style={{ textTransform: "uppercase" }}
                      >
                        {dealData?.cryptoValue +
                          " " +
                          dealData?.cryptoCode +
                          " " +
                          dealData?.network}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="media">
                  <div className="media-body">
                    <div>
                      <label>User Id :</label>{" "}
                      <span className="tx-medium">{dealData?.userId}</span>
                    </div>
                  </div>

                  <div className="media-body">
                    <div>
                      <label>Converted Fiat Amount</label>{" "}
                      <span className="tx-medium">
                        {dealData?.fiatValueAfterSwap +
                          " " +
                          dealData?.fiatCode}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="media mb-0">
                  <div className="media-body">
                    <div>
                      <label>Wallet :</label>{" "}
                      <div className="tx-medium d-flex flex-row">
                        {editWallet ? (
                          <div className="d-flex flex-row">
                            <div className={"d-flex flex-column "}>
                              <Form.Control
                                type="text"
                                placeholder={
                                  "Enter " +
                                  dealData?.cryptoCode +
                                  " " +
                                  dealData?.network +
                                  " address"
                                }
                                value={wallet}
                                onChange={(e) => setWallet(e.target.value)}
                              />{" "}
                              {error ? (
                                <div
                                  className="alert alert-danger mg-t-10"
                                  role="alert"
                                >
                                  {" "}
                                  {error}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <OverlayTrigger
                              placement={"top"}
                              overlay={<Tooltip>Confirm</Tooltip>}
                            >
                              <Button
                                variant="outline-primary"
                                className="btn-icon mg-l-15"
                                style={{
                                  padding: "0px",
                                }}
                                onClick={() => {
                                  setEditWalletLoading(true);
                                  updateWallet();
                                }}
                              >
                                {editWalletLoading ? (
                                  <SmallLoader />
                                ) : (
                                  <i className="fe fe-check"></i>
                                )}
                              </Button>
                            </OverlayTrigger>
                          </div>
                        ) : (
                          <span>{dealData?.wallet}</span>
                        )}{" "}
                        {type === "buy" &&
                        !editWallet &&
                        dealData?.status !== "closed" ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit amount</Tooltip>}
                          >
                            <Button
                              variant="outline-primary"
                              className="btn-icon mg-l-15"
                              style={{
                                height: "30px",
                                width: "30px",
                                padding: "0px",
                              }}
                              onClick={() => setEditWallet(!editWallet)}
                            >
                              <i className="fe fe-pencil"></i>
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                        {type === "buy" && editWallet ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Cancel</Tooltip>}
                          >
                            <Button
                              variant="outline-secondary"
                              className="btn-icon mg-l-5"
                              style={{
                                padding: "0px",
                              }}
                              onClick={() => {
                                setEditWallet(!editWallet);
                                setEditWalletLoading(false);
                                setWallet("");
                                setError(null);
                              }}
                            >
                              <i className="fe fe-close"></i>
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                      </div>
                      {editWalletError ? (
                        <div
                          className="alert alert-danger mg-t-10"
                          role="alert"
                        >
                          {" "}
                          {editWalletError}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {dealData.status === "created" && dealData.exchangeType === "buy" ? (
        <div className={"d-flex flex-row"}>
          <Button
            className="btn-secondary btn-md mg-r-2"
            variant="contained"
            color="secondary"
            onClick={() => {
              setAreYouSure(true);
              //decline(d.dealNumber);
            }}
          >
            Decline
          </Button>
          <Modal
            show={areYouSure}
            onHide={() => setAreYouSure(false)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              You are about to decline this deal. Are you sure?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setAreYouSure(false)}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  decline(dealData.dealNumber);
                  setAreYouSure(false);
                }}
              >
                Decline
              </Button>
            </Modal.Footer>
          </Modal>{" "}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default DealData;
