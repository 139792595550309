import { getUser } from "../../../api/users/getUser.tsx";
import React, { useEffect, useState } from "react";
import WorkerDashboard from "./WorkerDashboard";
import SenderDashboard from "./SenderDashboard";
import SalesManagerDashboard from "./sales/SalesManagerDashboard";

function DashboardSelector() {
  const [role, setRole] = useState("ROLE_WORKER");

  useEffect(() => {
    getUser()
      .then((res) => {
        setRole(res.data.roles[0]);
      })
      .catch((err) => {
        /*if (err.response.status === 403) {
          window.location.href = "/";
        }*/
      });
  }, []);

  let dashboardComponent;

  switch (role) {
    case "ROLE_WORKER":
      dashboardComponent = <WorkerDashboard />;
      break;
    case "ROLE_CRYPTO_SENDER":
      dashboardComponent = <SenderDashboard />;
      break;
    case "ROLE_SALES_MANAGER":
      dashboardComponent = <SalesManagerDashboard />;
      break;
    default:
      break;
  }

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      {dashboardComponent}
    </React.Suspense>
  );
}
export default DashboardSelector;
