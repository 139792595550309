import { Button, Form, FormGroup, Modal } from "react-bootstrap";
import React from "react";
import exchangeCurrency from "../../../api/companies/exchangeCurrency";
import { SmallLoader } from "../Loaders";

function ExchangeModal({ closeModal }) {
  const [amountFrom, setAmountFrom] = React.useState(0);
  const [amountTo, setAmountTo] = React.useState(0);
  const [currencyFrom, setCurrencyFrom] = React.useState("USD");
  const [currencyTo, setCurrencyTo] = React.useState("GEL");
  const [confirm, setConfirm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  function handleConfirm() {
    setConfirm(!confirm);
  }

  function handleExchange() {
    //  console.log("confirm", confirm);
    if (confirm) {
      setLoading(true);
      exchangeCurrency(amountTo, currencyTo, amountFrom, currencyFrom).then(
        (res) => {
          setLoading(false);
          if (res.status === 200) {
            closeModal();
          } else {
            setError("Something went wrong");
          }
        }
      );
    }
  }

  function handleClose() {
    closeModal();
  }

  return (
    <Form className="form-horizontal">
      <FormGroup className="form-group">
        <label className="form-label">From</label>
        <div className="input-group">
          <Form.Control
            type="number"
            className="form-control"
            placeholder="Amount"
            value={amountFrom}
            onChange={(e) => setAmountFrom(e.target.value)}
          />
          <Form.Select
            className="form-control"
            value={currencyFrom}
            onChange={(e) => setCurrencyFrom(e.target.value)}
          >
            <optgroup>
              <option value="USD">US Dollars</option>
              <option value="GEL">Georgian Lari</option>
              <option value="EUR">Euro</option>
              <option value="RSD">Serbian Dinar</option>
            </optgroup>
          </Form.Select>
        </div>
      </FormGroup>

      <FormGroup className="form-group">
        <label className="form-label">To</label>

        <div className="input-group">
          <Form.Control
            type="number"
            className="form-control"
            placeholder="Amount"
            value={amountTo}
            onChange={(e) => setAmountTo(e.target.value)}
          />
          <Form.Select
            className="form-control"
            value={currencyTo}
            onChange={(e) => setCurrencyTo(e.target.value)}
          >
            <optgroup>
              <option value="USD">US Dollars</option>
              <option value="GEL">Georgian Lari</option>
              <option value="EUR">Euro</option>
              <option value="RSD">Serbian Dinar</option>
            </optgroup>
          </Form.Select>
        </div>
      </FormGroup>
      <FormGroup className="form-group mb-0 justify-content-end">
        <div className="checkbox">
          <div className="custom-checkbox custom-control">
            <Form.Control
              aria-required={true}
              type="checkbox"
              data-checkboxes="mygroup"
              className="custom-control-input"
              id="checkbox-2"
              required={true}
              onChange={handleConfirm}
            />
            <Form.Label
              htmlFor="checkbox-2"
              className="custom-control-label mt-1"
            >
              Yes I checked and everything is correct. I am acknowledge and
              agree with{" "}
              <u className={"tx-semibold text-warning"}>
                Rules, Bonuses and Penalties policy
              </u>
            </Form.Label>
          </div>
        </div>
      </FormGroup>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleExchange}>
          {loading ? <SmallLoader /> : "Exchange"}
        </Button>
        {error ? error : ""}
      </Modal.Footer>
    </Form>
  );
}

export default ExchangeModal;
