import fetcher from "../../utils/Fetcher";

export class NetDocument {
  dealNumber: string;
  dealCode: string;
  generatedAt: string;
  company: string;
  type: string;
  amount: string;
  currency: string;
  number: string;
  details: string;
  gelRate: string;
  remainderBalance: RemainderBalance | null;
}

export class RemainderBalance {
  amount: string;
  code: string;
}


export async function getHistory() {
  return await fetcher("get", "/histories?period=week", {}, {});
}
