import CookiesStore from "../../utils/CookiesStore";
import axios from "axios";
import { API_URL } from "../Constants";
import fetcher from "../../utils/Fetcher";

export class Client {
    email: string;
    firstName: string;
    lastName: string;
    signInLastTimeAt: string;
    verifiedAt: string;
    locked: string;
    personnelBuyFee: string;
    personnelSellFee: string;
}

export class ClientsFilters {
    firstLevel: string[];
    secondLevel: string[];
}

export async function getClients(size, page, search, filter, sort) {
    let url = search
        ? "/sales/clients?email=" + search
        : "/sales/clients?" +
        "size=" +
        size +
        "&page=" +
        page +
        filter;

    return await fetcher("get", url, {}, {});
}

export async function getFilters() {
    const jwtToken = CookiesStore.getJwtToken();
    try {
        const response = await axios({
            method: "get",
            url: API_URL + "/filters/REWARDS",
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
}
