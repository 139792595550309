import React, {useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {getSalesManagerKPI} from "../../../../api/kpi/getSalesMangerKpi.tsx";

function SalesManagerKPI() {
    const [salesMangerKpi, setSalesMangerKpi] = useState(0);

    useEffect(() => {
        getSalesManagerKPI().then((res) => {
            let salesManagerKpiData = res.data
            setSalesMangerKpi(salesManagerKpiData)
        });
    }, []);

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h5">your success</Card.Title>
            </Card.Header>
            <Card.Footer>
                <Col>
                    <Row>
                        <Col xl={3} lg={5} md={7} xs={9}>
                            <Card className="sales-card bd bd radius-5">
                                <Row>
                                    <div className="col-8">
                                        <div className="ps-4 pt-4 pe-3 pb-4">
                                            <div className="">
                                                <h6 className="mb-2 tx-12 ">TOTAL PROFIT</h6>
                                            </div>
                                            <div className="pb-0 mt-0">
                                                <div className="d-flex">
                                                    <h4 className="tx-20 font-weight-semibold mb-2">
                                                        {salesMangerKpi?.totalProfit}
                                                    </h4>
                                                </div>
                                                <p className="mb-0 tx-12 text-muted">
                                                    Profit from all deals
                                                    <i className="fa fa-caret-up mx-2 text-success"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div
                                            className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                                            <i className="bi bi-asterisk tx-18 text-primary"></i>
                                        </div>
                                    </div>
                                </Row>
                            </Card>
                        </Col>
                        <Col xl={3} lg={5} md={7} xs={9}>
                            <Card className="sales-card bd bd radius-5">
                                <Row>
                                    <div className="col-8">
                                        <div className="ps-4 pt-4 pe-3 pb-4">
                                            <div className="">
                                                <h6 className="mb-2 tx-12 ">BONUS</h6>
                                            </div>
                                            <div className="pb-0 mt-0">
                                                <div className="d-flex">
                                                    <h4 className="tx-20 font-weight-semibold mb-2">
                                                        {salesMangerKpi?.managerBonus}
                                                    </h4>
                                                </div>
                                                <p className="mb-0 tx-12 text-muted">
                                                    Your monthly bonus
                                                    <i className="fa fa-caret-up mx-2 text-success"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div
                                            className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                                            <i className="bi bi-asterisk tx-18 text-primary"></i>
                                        </div>
                                    </div>
                                </Row>
                            </Card>
                        </Col>
                        <Col xl={3} lg={5} md={7} xs={9}>
                            <Card className="sales-card bd bd radius-5">
                                <Row>
                                    <div className="col-8">
                                        <div className="ps-4 pt-4 pe-3 pb-4">
                                            <div className="">
                                                <h6 className="mb-2 tx-12 ">TOTAL CLIENTS</h6>
                                            </div>
                                            <div className="pb-0 mt-0">
                                                <div className="d-flex">
                                                    <h4 className="tx-20 font-weight-semibold mb-2">
                                                        {salesMangerKpi?.totalClients}
                                                    </h4>
                                                </div>
                                                <p className="mb-0 tx-12 text-muted">
                                                    Clients who passed KYC
                                                    <i className="fa fa-caret-up mx-2 text-success"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div
                                            className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                                            <i className="bi bi-asterisk tx-18 text-primary"></i>
                                        </div>
                                    </div>
                                </Row>
                            </Card>
                        </Col>
                        <Col xl={3} lg={5} md={7} xs={9}>
                            <Card className="sales-card bd bd radius-5">
                                <Row>
                                    <div className="col-8">
                                        <div className="ps-4 pt-4 pe-3 pb-4">
                                            <div className="">
                                                <h6 className="mb-2 tx-12 ">ACTIVE CLIENTS</h6>
                                            </div>
                                            <div className="pb-0 mt-0">
                                                <div className="d-flex">
                                                    <h4 className="tx-20 font-weight-semibold mb-2">
                                                        {salesMangerKpi?.activeClients}
                                                    </h4>
                                                </div>
                                                <p className="mb-0 tx-12 text-muted">
                                                    Who done at least 1 deal
                                                    <i className="fa fa-caret-up mx-2 text-success"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div
                                            className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                                            <i className="bi bi-asterisk tx-18 text-primary"></i>
                                        </div>
                                    </div>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={3} lg={5} md={7} xs={9}>
                            <Card className="sales-card bd bd radius-5">
                                <Row>
                                    <div className="col-8">
                                        <div className="ps-4 pt-4 pe-3 pb-4">
                                            <div className="">
                                                <h6 className="mb-2 tx-12 ">FIRST LEVEL CLIENTS</h6>
                                            </div>
                                            <div className="pb-0 mt-0">
                                                <div className="d-flex">
                                                    <h4 className="tx-20 font-weight-semibold mb-2">
                                                        {salesMangerKpi?.firstLevelClients}
                                                    </h4>
                                                </div>
                                                <p className="mb-0 tx-12 text-muted">
                                                    Directly invited by you
                                                    <i className="fa fa-caret-up mx-2 text-success"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div
                                            className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                                            <i className="bi bi-asterisk tx-18 text-primary"></i>
                                        </div>
                                    </div>
                                </Row>
                            </Card>
                        </Col>
                        <Col xl={3} lg={5} md={7} xs={9}>
                            <Card className="sales-card bd bd radius-5">
                                <Row>
                                    <div className="col-8">
                                        <div className="ps-4 pt-4 pe-3 pb-4">
                                            <div className="">
                                                <h6 className="mb-2 tx-12 ">SECOND LEVEL CLIENTS</h6>
                                            </div>
                                            <div className="pb-0 mt-0">
                                                <div className="d-flex">
                                                    <h4 className="tx-20 font-weight-semibold mb-2">
                                                        {salesMangerKpi?.secondLevelClients}
                                                    </h4>
                                                </div>
                                                <p className="mb-0 tx-12 text-muted">
                                                    Invited by your first level clients
                                                    <i className="fa fa-caret-up mx-2 text-success"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div
                                            className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                                            <i className="bi bi-asterisk tx-18 text-primary"></i>
                                        </div>
                                    </div>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Card.Footer>
        </Card>
    );
}

export default SalesManagerKPI;
