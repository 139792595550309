import React, {useEffect} from "react";
import {Card, Col, Row} from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import styles from "./CalendarFilter.module.css";

export function Calendar({
                             open,
                             setOpen,
                             startDate,
                             endDate,
                             setStartDate,
                             setEndDate,
                         }) {
    let eventGuid = 0;
    let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today
    const INITIAL_EVENTS = [];

    function renderEventContent(eventInfo) {
        return (
            <>
                <b>{eventInfo.timeText}</b>
                <i>{eventInfo.event.title}</i>
            </>
        );
    }

    useEffect(() => {
        setOpen(true);
    }, []);

    const handleDateSelect = (selectInfo) => {
        /* let title = prompt("Please enter a new title for your event");


             calendarApi.unselect();

             if (title) {
                 calendarApi.addEvent({
                     id: createEventId(),
                     title,
                     start: selectInfo.startStr,
                     end: selectInfo.endStr,
                     allDay: selectInfo.allDay,
                 });
             }*/
        let calendarApi = selectInfo.view.calendar;
        calendarApi.activeDate = selectInfo.startStr;
        console.log(selectInfo);
        console.log(selectInfo.dateStr);

        if (startDate === "") {
            setStartDate(selectInfo.dateStr);
        } else if (endDate === "") {
            setEndDate(selectInfo.dateStr);
            setOpen(true);
        } else if (startDate !== "" && endDate !== "") {
            setStartDate(selectInfo.dateStr);
            setEndDate("");
        }

        //add start date to state
        //add end date to state
    };
    return (
        <div hidden={open} className={styles.calendarFilter} >
            <div className="">
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        {/*<div className={"mb-4"}>
                        {startDate ? (
                          <span className="tag tag-primary br-5">
                            From : {startDate}
                            <Link to="#" className="tag-addon bg-primary">
                              <i className="fe fe-x"></i>
                            </Link>{" "}
                          </span>
                        ) : (
                          ""
                        )}
                        {endDate ? (
                          <span className="tag tag-secondary br-5">
                            To : {endDate}
                            <Link to="#" className="tag-addon bg-secondary">
                              <i className="fe fe-x"></i>
                            </Link>{" "}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>*/}

                                        <div id="">
                                            <FullCalendar
                                                eventResizableFromStart={true}
                                                plugins={[
                                                    dayGridPlugin,
                                                    /*timeGridPlugin,*/
                                                    interactionPlugin,
                                                ]}
                                                headerToolbar={{
                                                    left: "prev",
                                                    center: "title",
                                                    right: "next",
                                                }}
                                                initialView="dayGridMonth"
                                                selectable={true}
                                                dateClick={handleDateSelect}
                                                stickyHeaderDates={true}
                                                eventContent={renderEventContent}
                                                viewClassNames={styles.view}
                                                slotLabelClassNames={styles.slotLabel}
                                                nowIndicatorClassNames={styles.nowIndicator}
                                                allDayClassNames={styles.allDay}
                                                dayHeaderClassNames={styles.dayHeader}
                                                dayCellClassNames={styles.dayCell}
                                                slotLaneClassNames={styles.slotLane}
                                                weekNumberClassNames={styles.weekNumber}
                                                eventClassNames={styles.event}
                                                moreLinkClassNames={styles.moreLink}
                                                /* style={{position: "relative", zIndex: 1, overlay : "visible"}}*/
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/* <!-- End Row --> */}
            </div>
        </div>
    );
};

Calendar.propTypes = {};

Calendar.defaultProps = {};

export default Calendar;
